import React, { useRef, useState } from 'react'
import { AlPHA_NUMERIC_KEY } from '../../Custom';
import axios from '../../axios';
import { useNavigate } from 'react-router';

const ShowPassword = (props) => {
    const [Show, setShow] = useState(false);
    return (
        <div>
            <p> {Show ? props.CountryApiKey : props.CountryApiKey.replace(/./g, "*")} <i
                className={Show ? "bx bx-hide box-icon-style" : 'bx bx-show box-icon-style'}
                title={Show ? "Hide Client Api Key" : 'Show Client Api Key'}
                onClick={() => setShow(!Show)}
                style={{
                    marginLeft: '10px'
                }}
            ></i></p>
        </div>
    );
};

const CountryApiKeyPopUp = (props) => {
    const navigate = useNavigate();
    const DivRef = useRef(null);
    const handleCloseClick = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            props.setCountryData({})
        }
    }

    const [NewKey, setNewKey] = useState("");

    const handleGenarate = () => {
        setNewKey(AlPHA_NUMERIC_KEY());
    }

    const handleUpdate = () => {
        if (props.CountryData && NewKey) {
            props.setLoader(true);
            const payload = {
                CountryApiKey: NewKey
            }
            axios
                .post("update_country_apikey/" + props.CountryData.Id + "?_method=PUT", payload)
                .then((response) => {
                    navigate("/clients", {
                        state: response.data.status.message,
                    });
                    props.setCountryData({});
                    props.setCountryServiceCall(true);
                    props.setLoader(false);
                })
                .catch(() => {
                    props.setLoader(false);
                });
        }
    }

    return (
        <>
            <div
                className='popup-background'
                onClick={(e) => {
                    handleCloseClick(e)
                }}
            >
                <div
                    className="card"
                    style={{
                        maxWidth: "25%",
                        height: "fit-content",
                        margin: "auto",
                        // textAlign: "center",
                        boxShadow: "0px 0px 7px 0px #5c5c5c",
                    }}
                    ref={DivRef}
                >
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-10 mb-3 text-center " >
                                <b>{props.CountryData.Name}</b>
                            </div>
                            <div className="col-md-2 text-right">
                                <button className="btn btn-secondary"
                                    onClick={() => {
                                        props.setCountryData({})
                                    }}
                                >x</button>
                            </div>

                            <div className="col-md-12 mb-3 text-center">

                                <div className='row mt-3'>
                                    <div>
                                        {props.CountryData.CountryApiKey ?
                                            <>
                                                <ShowPassword CountryApiKey={NewKey ? NewKey : props.CountryData.CountryApiKey ? props.CountryData.CountryApiKey : ""} />

                                                {
                                                    NewKey ?
                                                        <button type="submit" className="btn btn-primary px-5" onClick={
                                                            () => {
                                                                handleUpdate();
                                                            }
                                                        }>
                                                            Update
                                                        </button>

                                                        :
                                                        props.ModuleAccess === 'write' ?
                                                            <button type="submit" className="btn btn-primary px-5" onClick={() => {
                                                                handleGenarate();
                                                            }}>
                                                                ReGenarate
                                                            </button>
                                                            : null

                                                }
                                            </>
                                            :
                                            <>
                                                <p>
                                                    {NewKey ? NewKey : "Country api key not generated."}
                                                </p>
                                                {NewKey ?
                                                    <button type="submit" className="btn btn-primary px-5" onClick={() => {
                                                        handleUpdate();
                                                    }}>
                                                        Update
                                                    </button>

                                                    :
                                                    <button type="submit" className="btn btn-primary px-5" onClick={() => {
                                                        handleGenarate();
                                                    }}>
                                                        Genarate
                                                    </button>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default CountryApiKeyPopUp