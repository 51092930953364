import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import Loader from "../../Components/Loader";
import axios from "../../axios";

const SeverityList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [ModuleData, setModuleData] = useState([]);
  const [success, setSuccess] = useState("");
  const [ServiceCall, setServiceCall] = useState(true);
  const [search, setSearch] = useState("")
  const [ModuleAccess, setModuleAccess] = useState("");

  useEffect(() => {
    if (location.state) {
      setSuccess(location.state);
      navigate("/manage_severity", {
        state: null,
      });
    }
  }, [location, navigate]);

  useEffect(() => {
    if (success) {
      toast.success(success);
      setSuccess("");
    }
    return () => { };
  }, [success]);

  useEffect(() => {
    if (ServiceCall) {
      setLoader(true);
      axios
        .get("severities?Search=" + search)
        .then((response) => {
          setModuleData(response.data.data.data);
          setModuleAccess(response.data.data.ModuleAccess)
          setLoader(false);
          setServiceCall(false)
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [ServiceCall, search]);

  const handleSubmit = () => {
    setServiceCall(true)
  }

  const handleClearSearch = () => {
    setSearch("")
    setServiceCall(true)
  }

  return (
    <>
      <ToastContainer theme="colored" />
      {loader ? <Loader /> : null}
      <div className="row">
        <div className="col-md-8">
          <h6 className="mb-0 "> Severity List</h6>
        </div>
        {
          ModuleAccess === "write" ?
            <div className="col-md-4 text-right">
              <Link to="/add_severity" className="btn btn-primary">
                <i className="bx bx-plus me-1 font-22"></i>Severity
              </Link>
            </div>
            : null
        }
      </div>
      <hr />

      <div className="row">
        <div className="col-sm-3">
          <input
            type="text"
            className="form-control mb-3"
            id="search"
            name="search"
            placeholder="Search Here"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="col-sm-3">
          <button
            type="submit"
            className="btn btn-primary mb-3"
            title="Search"
            onClick={handleSubmit}
          >
            <i className="bx bx-search search-icon"></i>
          </button>
          &nbsp;
          {search ?
            <button
              type="button"
              className="btn btn-primary mb-3"
              title="Clear"
              onClick={handleClearSearch}
            >
              <i className="bx bx-eraser search-icon"></i>
            </button>
            : null}
        </div>
      </div>

      <div className="card">
        <div className="card-body horizontal-scroll">
          <div className="row g-3" style={{ marginBottom: "12px" }}>
            {/* {UserGroup.Id === 1 || UserGroup.Id === 2 ? (
            <div className="col-md-6">
              <select
                className="form-select"
                onChange={(e) => setClient(e.target.value)}
                value={Client}
              >
                <option value="">Select Client</option>
                {Clients.map((item) => {
                  return (
                    <option key={item.Id} value={item.Id}>
                      {item.Name}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : null} */}
          </div>

          <table className="table mb-0 table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Severity</th>
                <th>Waiting period</th>
                {
                  ModuleAccess === "write" ? <th>Action</th> : null
                }
              </tr>
            </thead>
            <tbody>
              {ModuleData.length > 0 ?
                ModuleData.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.Severity}</td>
                      <td>{item.DefaultWaitingPeriod}</td>

                      {ModuleAccess === "write" ?
                        <td className="action-buttons">
                          {/* <i
                      className="bx bx-trash text-danger"
                      title="Delete"
                    ></i> */}
                          <Link to={"/add_severity/" + item.Id}>
                            <i className="bx bx-pencil" title="Edit"></i>
                          </Link>
                        </td>
                        : null}
                    </tr>
                  );
                }) :
                <tr>
                  <td
                    colSpan={5}
                    style={{ textAlign: "center" }}>
                    <b>No Record Found</b>
                  </td>
                </tr>}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SeverityList;
