import React, { useEffect, useState } from "react";
import axios from "../../axios";
import Loader from "../../Components/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import DefaultLogo from "../../Images/foxilogic-disable.png"
import { CUSTOM_CLIENT_SELECT } from '../../Custom';

const ClientSeverityList = () => {
  var UserGroup = JSON.parse(localStorage.getItem("UserGroup"));
  var ClientId = JSON.parse(localStorage.getItem("ClientId"));

  const navigate = useNavigate();
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [Clients, setClients] = useState([]);
  const [Client, setClient] = useState(ClientId !== 0 ? ClientId : '');
  const [ModuleData, setModuleData] = useState([]);
  const [success, setSuccess] = useState("");
  const [ModuleAccess, setModuleAccess] = useState("");

  const getClientValue = () => {
    if (Client) {
      for (var i = 0; i < Clients.length; i++) {
        if (Clients[i].Id === parseInt(Client)) {
          return [{ value: Clients[i].Id, label: Clients[i].Name, image: Clients[i].image }];
        }
      }
    }
  }

  useEffect(() => {
    if (location.state) {
      if (location.state.response !== "") {
        setSuccess(location.state.response);
      }
      setClient(location.state.data)
      navigate("/client_severity", {
        state: null,
      });
    }
  }, [location, navigate]);

  useEffect(() => {
    if (success) {
      toast.success(success);
      setSuccess("");
    }
    return () => { };
  }, [success]);

  useEffect(() => {
    if (UserGroup.Id === 1 || UserGroup.Id === 2) {
      setLoader(true);
      axios
        .get("clients")
        .then((response) => {
          var TempData = response.data.data.data;
          var arr = [];
          for (var i = 0; i < TempData.length; i++) {
            var obj = TempData[i]
            obj["label"] = TempData[i].Name;
            obj["value"] = TempData[i].Id;
            obj["image"] = TempData[i].ClientLogo ? TempData[i].ClientLogo : DefaultLogo;
            arr.push(obj)
          }
          setClients(arr)
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [UserGroup.Id]);

  useEffect(() => {
    if (Client) {
      setLoader(true);
      axios
        .get("client_severities?ClientId=" + Client)
        .then((response) => {
          setModuleData(response.data.data.data);
          setModuleAccess(response.data.data.ModuleAccess)
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }

  }, [Client]);

  const OnchangeClient = (selected) => {
    setClient(selected.Id)
  }

  return (
    <>
      <ToastContainer theme="colored" />
      {loader ? <Loader /> : null}
      <div className="row">
        <div className="col-md-8">
          <h6 className="mb-0 ">Client Severity List</h6>
        </div>
        <div className="col-md-4 text-right">
        </div>
      </div>
      <hr />

      <div className="card">
        <div className="card-body horizontal-scroll">
          <div className="row g-3" style={{ marginBottom: "12px" }}>
            {UserGroup.Id === 1 || UserGroup.Id === 2 ? (
              <div className="col-md-6">
                {CUSTOM_CLIENT_SELECT({ ClientListOptions: Clients, Value: getClientValue(), OnchangeClient: OnchangeClient })}
              </div>
            ) : null}
          </div>

          <table className="table mb-0 table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Severity</th>
                <th>Waiting period</th>
                <th>Client</th>
                {
                  ModuleAccess === "write" ?
                    <th>Action</th>
                    : null
                }
              </tr>
            </thead>
            <tbody>
              {ModuleData.length > 0 && Client ?
                ModuleData.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.severity.Severity}</td>
                      <td>{item.WaitingPeriod}</td>
                      <td>{item.client.Name}</td>
                      {
                        ModuleAccess === "write" ?
                          <td className="action-buttons">
                            <Link to={"/add_client_severity/" + item.Id}>
                              <i className="bx bx-pencil" title="Edit"></i>
                            </Link>
                          </td>
                          : null
                      }
                    </tr>
                  );
                })
                :
                <tr>
                  <td
                    colSpan={5}
                    style={{ textAlign: "center" }}>
                    <b>No Record Found</b>
                  </td>
                </tr>}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ClientSeverityList;
