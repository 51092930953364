import React from 'react'
import { Link } from 'react-router-dom';

const CountryPage = (props) => {
  var UserGroup = JSON.parse(localStorage.getItem("UserGroup"));

  return (
    <div className="row mb-4">
      <div className="col-md-12">
        <div className="row mb-3 d-flex align-items-center">
          <div className="col-md-6">
            {/* <h5>Countries</h5> */}
            <div className="page-breadcrumb d-none d-sm-flex align-items-center ">
              <div className="breadcrumb-title pe-3">Countries</div>
              {
                props.UserGroup.Id === 1 || props.UserGroup.Id === 2 || props.UserGroup.Id === 4 || props.UserGroup.Id === 3 ?
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          {
                            props.UserGroup.Id === 1 || props.UserGroup.Id === 2 ?
                              <Link to="">
                                <i
                                  className="bx bx-home-alt"
                                  onClick={() => {
                                    props.setCountrywiseBaseStationData([]);
                                    props.setClientId("");
                                  }}
                                ></i>
                              </Link> :
                              <i className="bx bx-home-alt"></i>
                          }

                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          {props.ClientName}
                        </li>
                      </ol>
                    </nav>
                  </div> : null
              }
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-end ">
            <div className='row text-right'>
              <div className="col-md-12 d-flex gap-2" >
                <input
                  type="text"
                  className="form-control mt-1"
                  placeholder="Search country here..."
                  value={props.CountrySearch}
                  onChange={(e) => props.setCountrySearch(e.target.value)}
                />
                {
                  parseInt(UserGroup.Id) === 3 ?
                    <>
                      <div className="position-badge" onClick={() => {
                        props.setBsDownAlertFlag(!props.BsDownAlertFlag);
                      }}>
                        {props.AlertBadgeCount ? <div className="badge green">{props.AlertBadgeCount}</div>
                          : null}

                        <i className="bx bx-bell icon box-icon-style" style={{ fontSize: "33px" }} ></i>
                      </div>
                      <i
                        className="bx bx-notification box-icon-style"
                        style={{ fontSize: "30px" }}
                        onClick={() => {
                          props.setImpactedDeviceDownAlertFlag(!props.ImpactedDeviceDownAlertFlag);
                        }}
                      ></i>
                      <i
                        className="bx bx-bell-plus box-icon-style"
                        style={{ fontSize: "30px" }}
                        onClick={() => {
                          props.setBaseStationSpecificFlag(!props.BaseStationSpecificFlag);
                        }}
                      ></i>
                    </> : null
                }

              </div>
            </div>
          </div>
        </div>
        <div className="row dashboard-client-card-box">
          {/* <div className="col-md-1"></div> */}
          <div className="col-md-12 text-center">
            <div className="row">
              {props.CountrywiseBaseStationData.map((item, index) => {
                item.CountryName = item.CountryName
                  ? item.CountryName
                  : "-";
                return item.CountryName.toLowerCase().includes(
                  props.CountrySearch.trim().toLowerCase()
                ) ? (
                  <div key={index} className="col-sm-6">
                    <div
                      className="row dashboard-client-card"
                      // onClick={() => {
                      //   setClientCountryId(item.ClientCountryId);
                      //   setCountryName(item.CountryName);
                      // }}
                      style={
                        props.ClientCountryId === item.ClientCountryId
                          ? { border: "1px solid gray" }
                          : {}
                      }
                    >
                      <div className="col-sm-11" style={{ marginRight: "-30px" }}>
                        <h4>{item.CountryName}</h4>
                      </div>
                      <div className="col-sm-1">
                        {UserGroup.Id < 3 ?
                          <i
                            className="bx bx-cog box-icon-style"
                            style={{ fontSize: "25px" }}
                            onClick={() => {
                              props.setCronStatusClientId(item.ClientId);
                              props.setCronStatusClientCountryId(item.ClientCountryId);
                              props.setSelectedCronStatusPage("country")
                            }}
                          ></i> : null}
                      </div>
                      <div className="col-sm-12">
                        <div className="row cursor-pointer"
                          onClick={() => {
                            props.setClientCountryId(item.ClientCountryId);
                            props.setCountryName(item.CountryName);
                            props.setBitComSelectedState("BitState");
                          }}
                        >
                          <div className="col-sm-6 mb-2" style={{
                            verticalAlign: "middle",
                            display: "flex",
                          }}>
                            <p style={{
                              margin: "auto",
                              fontSize: "20px",
                              fontWeight: "bold",
                              verticalAlign: "middle",
                              textDecoration: "underline"
                            }}>BIT State</p>
                          </div>

                          <div className="col-sm-6">
                            <p style={{
                              margin: "auto",
                              fontSize: "20px",
                              fontWeight: "bold",
                              verticalAlign: "middle",
                              textDecoration: "underline"
                            }}>{parseInt(item.OKStateCount) + parseInt(item.KOStateCount) + parseInt(item.NOStateCount) + parseInt(item.WARNStateCount)}</p>
                            {/* <label>Total</label> */}
                          </div>
                          <div className="col-sm-3">
                            <h5>{item.OKStateCount}</h5>
                            <label>OK</label>
                          </div>
                          <div className="col-sm-3">
                            <h5>{item.KOStateCount}</h5>
                            <label>KO</label>
                          </div>
                          <div className="col-sm-3">
                            <h5>{item.NOStateCount}</h5>
                            <label>NO</label>
                          </div>
                          {/* <div className="col-sm-2"></div> */}
                          {/* <div className="col-sm-4">
                            <h5>{item.OKKOStateCount}</h5>
                            <label>OKKO</label>
                          </div> */}
                          <div className="col-sm-3">
                            <h5>{item.WARNStateCount}</h5>
                            <label>WARN</label>
                          </div>

                          {/* other state */}
                        </div>
                      </div>

                      <hr />
                      <div className="col-sm-12">
                        <div className="row cursor-pointer"
                          onClick={() => {
                            props.setClientCountryId(item.ClientCountryId);
                            props.setCountryName(item.CountryName);
                            props.setBitComSelectedState("ComState");
                          }}
                        >
                          <div className="col-sm-6 mb-2" style={{
                            verticalAlign: "middle",
                            display: "flex",
                          }}>
                            <p style={{
                              margin: "auto",
                              fontSize: "20px",
                              fontWeight: "bold",
                              verticalAlign: "middle",
                              textDecoration: "underline"
                            }}>Communication State</p>
                          </div>
                          <div className="col-sm-6">
                            <p style={{
                              margin: "auto",
                              fontSize: "20px",
                              fontWeight: "bold",
                              verticalAlign: "middle",
                              textDecoration: "underline"
                            }}>{parseInt(item.OKCommStateCount) + parseInt(item.KOCommStateCount) + parseInt(item.NOCommStateCount) + parseInt(item.WARNCommStateCount)}</p>
                            {/* <label>Total</label> */}
                          </div>
                          <div className="col-sm-3">
                            <h5>{item.OKCommStateCount}</h5>
                            <label>OK</label>
                          </div>
                          <div className="col-sm-3">
                            <h5>{item.KOCommStateCount}</h5>
                            <label>KO</label>
                          </div>
                          <div className="col-sm-3">
                            <h5>{item.NOCommStateCount}</h5>
                            <label>NO</label>
                          </div>
                          <div className="col-sm-3">
                            <h5>{item.WARNCommStateCount}</h5>
                            <label>WARN</label>
                          </div>
                        </div>
                      </div>
                      <hr />
                      {/* end other state */}
                      {/* tickets */}

                      {props.TicketModuleAccess !== "none" ?
                        <>
                          <div className="col-sm-12 mt-2">
                            <span><b>Sigfox Tickets</b></span>
                          </div>
                          <Link to={"/tickets-list/" + item.ClientCountryId} title="Show Tickets">
                            <div className="col-sm-12">
                              <div className="d-flex justify-content-sm-between dashboard-ticket-card" style={{ overflow: "auto", gap: "10px" }}>
                                <div className="total-ticket">
                                  {item.TotalTickets}<br />Total Tickets
                                </div>
                                <div className="ticket-resolve">
                                  {item.ResolvedTickets}<br />Resolved Tickets
                                </div>
                                <div className="ticket-open">
                                  {item.OpenTickets}<br />Open Tickets
                                </div>
                                <div className="ticket-close">
                                  {item.ClosedTickets}<br />Closed Tickets
                                </div>
                                <div className="ticket-incident_dispatch">
                                  {item.IncidentDispatchedTickets}<br />Incident Dispatch
                                </div>
                                <div className="ticket-deferred">
                                  {item.DeferredTickets}<br />Deferred
                                </div>
                                <div className="ticket-power-confirmation">
                                  {item.PowerConfirmationTickets}<br />Power Confirmation
                                </div>
                              </div>
                            </div>
                          </Link>
                        </>
                        : null}

                      {props.CabisenseTicketModuleAccess !== "none" ?
                        <>
                          <div className="col-sm-12 mt-2">
                            <span><b>Cabisense Tickets</b></span>
                          </div>

                          <Link to={"/cabisense-tickets-list/" + item.ClientCountryId} title="Show Tickets">
                            <div className="col-sm-12">
                              <div className="d-flex justify-content-sm-between dashboard-ticket-card" style={{ overflow: "auto", gap: "10px" }}>
                                <div className="total-ticket">
                                  {item.TotalCabisenseTickets}<br />Total Tickets
                                </div>
                                {/* <div className="ticket-resolve">
                                      {item.ResolvedCabisenseTickets}<br />Resolved Tickets
                                    </div> */}
                                <div className="ticket-open">
                                  {item.OpenCabisenseTickets}<br />Open Tickets
                                </div>
                                <div className="ticket-close">
                                  {item.ClosedCabisenseTickets}<br />Closed Tickets
                                </div>
                                <div className="ticket-incident_dispatch">
                                  {item.IncidentDispatchedCabisenseTickets}<br />Incident Dispatch
                                </div>
                                <div className="ticket-deferred">
                                  {item.DeferredCabisenseTickets}<br />Deferred
                                </div>
                                {/* <div className="ticket-power-confirmation">
                                  {item.PowerConfirmationCabisenseTickets}<br />Power Confirmation
                                </div> */}
                              </div>
                            </div>
                          </Link>
                        </>
                        : null
                      }

                      {
                        props.MerakiTicketModuleAccess !== "none" ?
                          <>
                            {/* Meraki Tickets */}
                            <div className="col-sm-12 mt-2">
                              <span><b>Meraki Tickets</b></span>
                            </div>

                            <Link to={"/meraki-tickets-list/" + item.ClientCountryId} title="Show Tickets">
                              <div className="col-sm-12">
                                <div className="d-flex justify-content-sm-between dashboard-ticket-card" style={{ overflow: "auto", gap: "10px" }}>
                                  <div className="total-ticket">
                                    {item.TotalMerakiTickets}<br />Total Tickets
                                  </div>
                                  <div className="ticket-resolve">
                                    {item.ResolvedMerakiTickets}<br />Resolved Tickets
                                  </div>
                                  <div className="ticket-open">
                                    {item.OpenMerakiTickets}<br />Open Tickets
                                  </div>
                                  <div className="ticket-close">
                                    {item.ClosedMerakiTickets}<br />Closed Tickets
                                  </div>
                                  <div className="ticket-incident_dispatch">
                                    {item.IncidentDispatchedCabisenseTickets}<br />Incident Dispatch
                                  </div>
                                  <div className="ticket-deferred">
                                    {item.DeferredMerakiTickets}<br />Deferred
                                  </div>
                                  {/* <div className="ticket-power-confirmation">
                                  {item.PowerConfirmationMerakiTickets}<br />Power Confirmation
                                </div> */}
                                </div>
                              </div>
                            </Link>
                            {/* Meraki Tickets Ends */}
                          </>
                          : null}

                      {/* tickets */}
                    </div>
                    {/* <h6 className="dashboard-client-card-label">{item.CountryName}</h6> */}
                  </div>
                ) : null;
              })}
            </div>
          </div>
          {/* <div className="col-md-1"></div> */}
        </div>
      </div>
    </div >
  )
}

export default CountryPage