import React from 'react'

const DevicesPop = (props) => {
    const UserGroup = JSON.parse(localStorage.getItem("UserGroup"));

    return (
        <>
            {/* // <div
        //     style={{
        //         position: "absolute",
        //         zIndex: 9999,
        //         height: "100vh",
        //         width: "100%",
        //         top: -60,
        //         left: 0,
        //         display: "flex",
        //         background: 'rgba(0, 0, 0, 0.5)'
        //     }}
        // >
        //     <div
        //         className="card"
        //         style={{
        //             width: "auto",
        //             height: "fit-content",
        //             margin: "auto",
        //             textAlign: "center",
        //             boxShadow: "0px 0px 5px 0px #5c5c5c",
        //             maxHeight: '450px',
        //             maxWidth: '90%'
        //         }}
        //     > */}
            <div className="card-body " >
                <i className="bx bx-x" title="Close" style={{ position: 'absolute', right: '5px', cursor: 'pointer', fontSize: '23px', fontWeight: 'bold' }} onClick={() => {
                    props.setFilter('')
                    props.setDevicesPopData([])
                    props.setSiteSelected('')
                }}></i>
                <div className="row">
                    <div className="col-md-12 text-left">
                        {
                            props.ModuleAccess === "write" ?
                                <button onClick={() => props.ExportInternalDevicesDt(props.SiteSelected, props.Filter)} type="button" title="Export" className="btn btn-primary" style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px' }}>Export</button> : null
                        }

                    </div>
                </div>
                <div className="row horizontal-scroll mt-2" style={{ maxHeight: '70vh' }}>
                    <div className="col-md-12 text-center">
                        <table className="table mb-0 table-striped">
                            <thead>
                                <tr>
                                    <th style={{ width: "70px" }}>#</th>
                                    {
                                        (UserGroup.Id === 1 || UserGroup.Id === 2 || UserGroup.Id === 3) ?
                                            <th>Channel partner</th> : null
                                    }
                                    <th>Com</th>
                                    <th>Device Id</th>
                                    <th>Device Name</th>
                                    <th>Device Type</th>
                                    <th>Device Type Group</th>
                                    <th>Group Name</th>
                                    <th>Region</th>
                                    <th>Site</th>
                                    <th>Site Status</th>
                                    <th>Basestation Id</th>
                                    <th>Com State Text</th>
                                    <th>Is Single Covered</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.DevicesPopData.length > 0 ?
                                    props.DevicesPopData.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                {
                                                    (UserGroup.Id === 1 || UserGroup.Id === 2 || UserGroup.Id === 3) ?
                                                        <td>{item.ChannelPartner}</td> : null
                                                }

                                                <td>{item.Com}</td>
                                                <td>{item.DeviceId}</td>
                                                <td>{item.DeviceName}</td>
                                                <td>{item.DeviceType}</td>
                                                <td>{item.DeviceTypeGroup}</td>
                                                <td>{item.GroupName}</td>
                                                <td>{item.Region}</td>
                                                <td>{item.Site}</td>
                                                <td>{item.SiteStatus}</td>
                                                <td>{item.basestation_Id}</td>
                                                <td>{item.comStateText}</td>
                                                <td>{item.isSingleCovered === 1 ? 'True' : 'False'}</td>
                                            </tr>
                                        )
                                    }) : <tr>
                                        <td
                                            colSpan={14}
                                            style={{ textAlign: "center" }}>
                                            <b>No Record Found</b>
                                        </td>
                                    </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DevicesPop