import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from "../../axios"
import Loader from '../../Components/Loader'

const TicketAttachment = (props) => {
    const DivRef = useRef(null);
    const [Files, setFiles] = useState([]);
    const [Error, setError] = useState("");
    const [ApiFiles, setApiFiles] = useState([]);
    const [ServiceCall, setServiceCall] = useState(true);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        if (props.CountryID && props.TicketAttachmentId && ServiceCall) {
            setLoader(true);
            axios.get('get_ticket_attachment/' + props.TicketAttachmentId + '?ClientCountryId=' + props.CountryID)
                .then((response) => {
                    setApiFiles(response.data.data)
                    setServiceCall(false)
                    setLoader(false);
                })
                .catch((error) => {
                    setLoader(false);
                })
        }

    }, [props.CountryID, props.TicketAttachmentId, ServiceCall]);

    const HandleCloseClick = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            props.setTicketAttachmentId("");
        }

    };

    const HandleFileChange = (e) => {
        const SelectedFiles = Array.from(e.target.files);
        if (SelectedFiles.length > 3) {
            setError("You can only upload up to 3 files.");
        } else {
            setFiles(SelectedFiles);
            setError("");
        }
    };

    const HandleValidation = () => {
        var flag = true;
        if (Files.length === 0) {
            flag = false;
            setError("Please select files.");
        }

        if (Files.length > 3) {
            setError("Select only 3 file allowed.");
            flag = false;
        }

        const AllowedTypes = [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
            'application/pdf', // pdf
            'image/png', // png
            'image/jpeg', // jpg, jpeg
            'image/jpg', // jpg, jpeg
        ];

        for (let file of Files) {
            if (file.size > 1048576) {  // 1MB
                flag = false;
                setError("Please select a file that is 1MB or smaller. Each file must not exceed the 1MB size limit.");
                break;
            }

            if (!AllowedTypes.includes(file.type)) {
                flag = false;
                setError("Only .xlsx, .docx, .pdf, .png, .jpg, and .jpeg files are allowed.");
                break;
            }
        }

        return flag
    }

    const HandleSubmit = async () => {
        if (HandleValidation() && props.CountryID && props.TicketAttachmentId) {
            console.log("first")
            const Data = new FormData();
            Data.append('ClientCountryId', props.CountryID);
            Data.append('TicketId', props.TicketAttachmentId);
            Files.forEach((file) => {
                Data.append('files[]', file);
            });
            setLoader(true);
            axios.post('ticket_attachment', Data)
                .then((response) => {
                    toast.success(response.data.status.message)
                    setServiceCall(true)
                    setLoader(false);

                })
                .catch((error) => {
                    setLoader(false);
                })
        }
    };

    return (
        <>
            {loader ? <Loader /> : null}
            <div
                className='popup-background'
                onClick={(e) => {
                    HandleCloseClick(e);
                }}
            >
                <div
                    className="card"
                    style={{
                        maxWidth: "30%",
                        height: "fit-content",
                        margin: "auto",
                        boxShadow: "0px 0px 5px 0px #5c5c5c",
                    }}
                    ref={DivRef}
                >
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-10 mb-3" style={{ textAlign: "center" }}>
                                <h5 style={{ marginLeft: "68px" }}>Ticket Attachments ({props.TicketAttachmentId})</h5>
                            </div>

                            <div className="col-md-2 text-right">
                                <button className="btn btn-secondary"
                                    onClick={() => {
                                        props.setTicketAttachmentId("");
                                    }}
                                >x</button>
                            </div>

                            {
                                props.TicketsModuleAccess === 'write' ?
                                    <>
                                        {/* File Input */}
                                        <div className="col-md-12 mb-3">
                                            <input
                                                type="file"
                                                multiple
                                                className="form-control"
                                                onChange={HandleFileChange}
                                            />
                                            {Error && <p style={{ color: 'red' }} className='my-1'>{Error}</p>}
                                        </div>

                                        {/* Submit Button */}
                                        <div className="col-md-12 mb-3" style={{ textAlign: "center" }}>
                                            <button
                                                className="btn btn-primary"
                                                style={{
                                                    boxShadow: '0px 0px 5px 0px #4f4f4f',
                                                    borderRadius: '15px',
                                                    padding: '5px 20px',
                                                }}
                                                onClick={HandleSubmit}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </>
                                    : null
                            }



                            {/* Existing Files from API */}
                            <div className="col-md-12 mt-3 ">
                                {ApiFiles.length > 0 && (
                                    <table className="table table-bordered mt-3">
                                        <thead>
                                            <tr style={{ textAlign: "center" }}>
                                                <th>#</th>
                                                <th>File Name</th>
                                                <th>Download</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ApiFiles.map((item, index) => (
                                                <tr key={index} style={{ textAlign: "center" }}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.FileName}</td>
                                                    <td ><i className='bx bx-download box-icon-style' onClick={() => {
                                                        var link = document.createElement("a");
                                                        link.target = "_blank";
                                                        link.href = item.Url;
                                                        document.body.appendChild(link);
                                                        link.click();
                                                        document.body.removeChild(link);
                                                    }}></i></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TicketAttachment;
