import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link } from 'react-router-dom';
import Loader from "../../Components/Loader";
import axios from "../../axios";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "../../Components/Alert";
import Switch from "react-switch";
import Pagination from "../../Components/Pagination";

const List = () => {
  const location = useLocation();
  const navigate = useNavigate();

  var url = new URL(window.location.href);

  const page = url.searchParams.get('PageNo') || 1
  const limit = url.searchParams.get('Limit') || 100
  const search = url.searchParams.get('Search') || ''

  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [ModuleData, setModuleData] = useState([])
  const [searchInput, setSearchInput] = useState(search)
  const [ServiceCall, setServiceCall] = useState(true)
  const [DeleteUserId, setDeleteUserId] = useState("");

  const [NoOfRecords, setNoOfRecords] = useState(0);
  const [searched, setSearched] = useState(false)
  const [ModuleAccess, setModuleAccess] = useState("");

  useEffect(() => {
    if (success) {
      toast.success(success);
      setSuccess("");
    }
    return () => { };
  }, [success]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError("");
    }
    return () => { };
  }, [error]);

  useEffect(() => {
    if (location.state) {
      setSuccess(location.state);
      navigate("/channel_partner_group_config", {
        state: null,
      });
    }
  }, [location, navigate]);

  useEffect(() => {
    if (ServiceCall) {
      setLoader(true)
      axios.get("get_all_channel_partner_groups?Search=" + search + "&PageNo=" + page + "&Limit=" + limit)
        .then((response) => {
          setModuleData(response.data.data.data)
          setModuleAccess(response.data.data.ModuleAccess)
          setNoOfRecords(response.data.NoOfRecords);
          setLoader(false)
          setServiceCall(false)
        })
        .catch((error) => {
          setLoader(false)
        })
    }
  }, [ServiceCall, search, page, limit])


  const handleClearSearch = () => {
    setSearchInput("")
    setServiceCall(true)
    navigate("?page=" + page + "&Limit=" + limit + "&Search=")

  }

  const AlertResponse = (response) => {
    if (response === true) {
      setLoader(true);
      axios
        .get("delete_channel_partner_group?DeleteId=" + DeleteUserId)
        .then((response) => {
          setServiceCall(true);
          setDeleteUserId("");
          setSuccess("Record deleted successfully");
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    } else {
      setDeleteUserId("");
    }
  };

  const ChangeActiveStatus = (Id, Active) => {
    const Payload = {
      "Id": Id,
      "Active": parseInt(Active) === 0 ? 1 : 0
    }
    axios.post("change_active_status_channel_partner_group", Payload)
      .then((response) => {
        setServiceCall(true)

      })
      .catch((error) => {
      })
  }


  useEffect(() => {
    if (searched) {
      navigate("?page=" + page + "&Limit=" + limit + "&Search=" + searchInput)
      setServiceCall(true);
      setSearched(false)
    }
  }, [navigate, searchInput, page, limit, searched])

  useEffect(() => {
    setServiceCall(true);
  }, [limit, page, search]);

  return (
    <div>
      <ToastContainer theme="colored" />
      {loader ? <Loader /> : null}

      {DeleteUserId ?
        <Alert
          message="Are you sure, you want to delete this record?"
          AlertResponse={AlertResponse}
        />
        :
        ""
      }

      <div className="row">
        <div className="col-md-8">
          <h6 className="mb-0 "> Channel Partner Group List</h6>
        </div>
        {
          ModuleAccess === "write" ?
            <div className="col-md-4 text-right">
              <Link to="/add_channel_partner_group_config" className="btn btn-primary">
                <i className="bx bx-plus me-1 font-22"></i>Add Channel Partner Group
              </Link>
            </div> : null
        }

      </div>
      <hr />

      <div className="row">
        <div className="col-sm-3">
          <input
            type="text"
            className="form-control mb-3"
            id="search"
            name="search"
            placeholder="Search Here"
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value)

            }
            }
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                setSearched(true)
              }
            }}
          />
        </div>
        <div className="col-sm-3">
          <button
            type="submit"
            className="btn btn-primary mb-3"
            title="Search"
            onClick={() => {
              setSearched(true)
            }}
          >
            <i className="bx bx-search search-icon"></i>
          </button>
          &nbsp;
          {searchInput ?
            <button
              type="button"
              className="btn btn-primary mb-3"
              title="Clear"
              onClick={handleClearSearch}
            >
              <i className="bx bx-eraser search-icon"></i>
            </button>
            : null}
        </div>
      </div>

      <div className="card mb-2">
        <div className="card-body horizontal-scroll">
          <div className="row g-3" style={{ marginBottom: "12px" }}>
          </div>

          <div style={{ maxHeight: '64vh' }} className=" horizontal-scroll">
            <table className="table mb-0 table-striped ticket-table table-hover">
              <thead style={{ zIndex: "99" }}>
                <tr>
                  <th>#</th>
                  <th>Group Name</th>
                  <th>Channel Partner</th>
                  <th>Active</th>
                  {
                    ModuleAccess === "write" ?
                      <th>Action</th>
                      : null
                  }

                </tr>
              </thead>
              <tbody>
                {ModuleData.length > 0 ?
                  ModuleData.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{((page - 1) * limit) + index + 1}</td>
                        <td>{item.GroupName}</td>
                        <td>{item.ChannelPartnerName}</td>
                        <td>
                          <Switch
                            className="login-switch"
                            onColor={"#136dfa"}
                            height={25}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            checked={parseInt(item.Active) === 1 ? true : false}
                            onChange={() => ChangeActiveStatus(item.Id, item.Active)}
                          />
                        </td>

                        {
                          ModuleAccess === "write" ?
                            <td className="action-buttons">
                              <i
                                className="bx bx-trash text-danger"
                                title="Delete"
                                onClick={() => setDeleteUserId(item.Id)}
                              ></i>
                              <Link to={"/add_channel_partner_group_config/" + item.Id}>
                                <i className="bx bx-pencil" title="Edit"></i>
                              </Link>
                            </td> : null
                        }

                      </tr>
                    );
                  }) :
                  <tr>
                    <td
                      colSpan={5}
                      style={{ textAlign: "center" }}>
                      <b>No Record Found</b>
                    </td>
                  </tr>}
              </tbody>
            </table>
          </div>
        </div>
        <div style={{
          marginBottom: "20px",
          marginLeft: "14px"
        }}>
          {
            NoOfRecords > 0 ?
              <Pagination
                records={ModuleData.length}
                link={"channel_partner_group_config"}
                SelectedPage={page}
                search={"?page=" + page + "&Limit=" + limit + "&Search=" + searchInput}
                NoOfRecords={NoOfRecords}
                limit={limit}
              /> : null
          }
        </div>
      </div>

    </div >
  )
}

export default List