import React, { useRef } from 'react'
import { DefaultEditor } from 'react-simple-wysiwyg';

const EditPopup = (props) => {
    const DivRef = useRef(null);
    const onChange = (e) => {
        props.setEditText(e.target.value)
        // setHtml(e.target.value)
    }

    const handleClose = () => {
        props.setIncidentType("");
        props.setRecommendIndex("");
        props.setEditText("")
    }

    const handleCloseClick = (event) => {
        if (DivRef.current && !DivRef.current.contains(event.target)) {
            handleClose();
        }
    }

    return (
        <div
            className='popup-background'
            onClick={(e) => {
                handleCloseClick(e)
            }}
        >
            <div
                className="card"
                style={{
                    maxWidth: "50%",
                    height: "fit-content",
                    margin: "auto",
                    textAlign: "center",
                    boxShadow: "0px 0px 5px 0px #5c5c5c",
                }}
                ref={DivRef}
            >

                <div className="card-body horizontal-scroll">
                    <div className="row">
                        <div className="col-md-10 text-left mb-3">
                            <h5>Edit {props.ModuleData[props.recommentIndex].IncidentCode}
                                &nbsp;{props.incidentType} Recommended Fix Config</h5>
                        </div>
                        <div className="col-md-2 text-right">
                            <button className="btn btn-secondary"
                                onClick={() => handleClose()}>x</button>
                        </div>
                        <div className="col-md-12 mb-3">
                            <DefaultEditor style={{ textAlign: "left" }} value={props.EditText} onChange={onChange} />
                        </div>
                        <div className='col-md-12 mb-3'>
                            {/* <span className="text-danger">{props.EditTestError}</span> */}
                        </div>

                        {
                            props.ModuleAccess === 'write' ?
                                <div className='col-sm-12'>
                                    <button
                                        type="submit"
                                        className="btn btn-primary px-5"
                                        style={{ float: "right", marginLeft: "10px" }}
                                        onClick={() => props.updateRecommendFix("Save")}
                                    >
                                        Save
                                    </button>

                                    {
                                        ((props.UserGroup.Id === 1 || props.UserGroup.Id === 2) && props.ClientID.toString() === "-1") ? <button
                                            type="submit"
                                            className="btn btn-primary px-5"
                                            style={{ float: "right" }}
                                            onClick={() => props.updateRecommendFix("SaveToAll")}
                                        >
                                            Apply to all
                                        </button> : null
                                    }

                                </div>

                                : null
                        }

                    </div>
                </div>
            </div>

        </div>
    )
}

export default EditPopup