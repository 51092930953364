import React, { useEffect, useState } from 'react';
import { AlPHA_NUMERIC_KEY } from '../../Custom';
import axios from '../../axios';
import { toast } from 'react-toastify';
import Alert from '../../Components/Alert';
import ReactCountryFlag from 'react-country-flag';

const ClientApiKey = (props) => {
    const [MaintenanceList, setMaintenanceList] = useState([]);
    const [List, setList] = useState([]);
    const [Maintenance, setMaintenance] = useState("");
    const [NewKey, setNewKey] = useState("");
    const [error, setError] = useState({ Maintenance: '', ApiKey: '', Country: '' });
    const [ServiceCall, setServiceCall] = useState(false);
    const [isRotating, setIsRotating] = useState(false);
    const [DeleteMaintenanceId, setDeleteMaintenanceId] = useState("");
    const [MasterApiFlag, setMasterApiFlag] = useState(true);
    const [CountryList, setCountryList] = useState([]);
    const [CountryId, setCountryId] = useState("");

    useEffect(() => {
        if (MasterApiFlag) {
            props.setLoader(true);
            axios.get(`client_custom_sp?ClientId=${props.ApiKeyClientId}`)
                .then((response) => {
                    setMaintenanceList(response.data.data.CustomSpData);
                    setCountryList(response.data.data.CountryData);
                    setServiceCall(true);
                    setMasterApiFlag(false);
                })
                .catch((error) => {
                })
                .finally(() => {
                    props.setLoader(false);
                });
        }
    }, [MasterApiFlag, props]);

    useEffect(() => {
        if (ServiceCall && props.ApiKeyClientId) {
            axios.get("client_apikey?ClientId=" + props.ApiKeyClientId)
                .then((response) => {
                    const sortedData = response.data.data.sort((a, b) => a.CustomSpId - b.CustomSpId);
                    setList(sortedData);
                    setServiceCall(false);
                })
                .catch((error) => {
                });
        }
    }, [ServiceCall, props]);

    const handleGenerate = () => {
        setIsRotating(true);
        setNewKey(AlPHA_NUMERIC_KEY());

        setTimeout(() => {
            setIsRotating(false);
        }, 500);
    };

    const handleUpdate = (e) => {
        e.preventDefault();  // Prevent default form submission
        let IsValid = true;
        let ValidationErrors = { ApiKey: '', Country: '' };

        // Validate API key
        if (!NewKey) {
            ValidationErrors.ApiKey = 'API key cannot be empty. Please generate an API key.';
            IsValid = false;
        }

        if (Maintenance && !CountryId) {
            ValidationErrors.Country = 'Please select country.';
            IsValid = false;
        }

        setError(ValidationErrors);

        if (IsValid && props.ApiKeyClientId) {
            props.setLoader(true);
            axios.post("client_apikey", {
                "ClientId": props.ApiKeyClientId,
                "CustomSpId": Maintenance ? Maintenance : null,
                "ApiKey": NewKey,
                "ClientCountryId": CountryId ? CountryId : null
            })
                .then((response) => {
                    toast.success(response.data.status.message);
                    setServiceCall(true);
                    setMaintenance("");
                    setCountryId("");
                    setNewKey("");
                })
                .catch(() => {
                })
                .finally(() => {
                    props.setLoader(false);
                });
        }
    };

    const AlertResponse = (response) => {
        if (response) {
            props.setLoader(true);

            axios.delete('/client_apikey/' + DeleteMaintenanceId)
                .then((response) => {
                    toast.success(response.data.status.message);
                    setServiceCall(true);
                    setDeleteMaintenanceId("");
                })
                .catch((error) => {
                })
                .finally(() => {
                    props.setLoader(false);
                });
        } else {
            setDeleteMaintenanceId("");
        }
    };

    return (
        <div className='popup-background'>
            {DeleteMaintenanceId !== "" ? (
                <Alert
                    message="Are you sure, you want to delete this record?"
                    AlertResponse={AlertResponse}
                />
            ) : null}
            <div className="card" style={{ height: "fit-content", margin: "auto", width: "60%", boxShadow: "0px 0px 7px 0px #5c5c5c" }}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-4 mb-3 text-center"></div>
                        <div className="col-md-4 mb-3 text-center">
                            <h5>Client Api Keys</h5>
                        </div>
                        <div className="col-md-4 text-right">
                            <button className="btn btn-secondary" onClick={() => props.setApiKeyClientId("")}>x</button>
                        </div>

                        {
                            props.ModuleAccess === 'write' ?
                                <>
                                    <div className="col-md-12 row mb-2 mt-3" style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                        <div className='col-md-3'>
                                            <select
                                                className="form-select"
                                                onChange={(e) => setMaintenance(e.target.value)}
                                                value={Maintenance}
                                            >
                                                <option value="">Select Service Provider</option>
                                                {MaintenanceList.map((item, index) => (
                                                    <option key={index} value={item.Id}>{item.CustomSp}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='col-md-3'>
                                            <select
                                                className="form-select mb-3"
                                                onChange={(e) => setCountryId(e.target.value)}
                                                value={CountryId}
                                            >
                                                <option value="">Select Country</option>
                                                {CountryList.map((item, index) => (
                                                    <option key={index} value={item.Id}>
                                                        {item.CountryCode ? (
                                                            <ReactCountryFlag
                                                                className="add_country_flag mx-5"
                                                                countryCode={item.CountryCode}
                                                                aria-label={item.CountryCode}
                                                            />
                                                        ) : (
                                                            <i className="bx bx-flag mx-1 p-0" title="Country" style={{ fontSize: "21px", cursor: "pointer" }}></i>
                                                        )}
                                                        &nbsp;&nbsp;&nbsp;{item.CountryName}
                                                    </option>
                                                ))}
                                            </select>
                                            {error.Country && <p className="text-danger mt-1">{error.Country}</p>}
                                        </div>

                                        <div className='col-md-4'>
                                            <div className="input-group">
                                                <input type="text" className="form-control custom-readonly-input" value={NewKey} readOnly />
                                                <span className="input-group-text p-0 px-2 custom-readonly-input">
                                                    <i
                                                        className={`bx bx-revision box-icon-style ${isRotating ? "rotate" : ""}`}
                                                        title="Refresh"
                                                        onClick={handleGenerate}
                                                    ></i>
                                                </span>
                                            </div>
                                            {error.ApiKey && <p className="text-danger mt-1">{error.ApiKey}</p>}
                                        </div>

                                        <div className='col-md-2 text-left' style={{ alignItems: "center" }}>
                                            {props.ModuleAccess === 'write' ?
                                                <button className='btn btn-primary' onClick={handleUpdate}>Save</button> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-12'><hr /></div>
                                </>
                                : null
                        }




                        <div className='col-md-12'>
                            <table className="table mb-0 table-striped text-center">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th style={{ textAlign: "center" }}>Service Provider</th>
                                        <th>Country</th>
                                        <th>Api Key</th>
                                        {
                                            props.ModuleAccess === 'write' ?
                                                <th>Action</th> : null
                                        }

                                    </tr>
                                </thead>
                                <tbody>
                                    {List.length > 0 ? (
                                        List.map((item, i) => (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{item.CustomSp ? item.CustomSp : "All"}</td>
                                                <td>{item.CountryName ? <>
                                                    {item.CountryName}
                                                </> : "All"}</td>
                                                <td>{item.ApiKey}</td>
                                                {
                                                    props.ModuleAccess === 'write' ?
                                                        <td>
                                                            <i
                                                                className="bx bx-trash text-danger cursor-pointer"
                                                                title="Delete"
                                                                onClick={() => { setDeleteMaintenanceId(item.Id); window.scrollTo(0, 0); }}
                                                            ></i>
                                                        </td> : null
                                                }

                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5">No data found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientApiKey;
