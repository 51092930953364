import React from 'react'
import { Link } from "react-router-dom";
import BitSunburstChart from './BitSunburstChart';
import CommSunburstChart from './CommSunburstChart ';
import TicketCharts from './TicketCharts';
import CabisenseTicketCharts from './CabisenseTicketCharts';
import DefaultLogo from "../../Images/foxilogic-disable.png"
import MerakiTicketCharts from './MerakiTicketCharts';

const ClientDetails = (props) => {
    var UserGroup = JSON.parse(localStorage.getItem("UserGroup"));

    return (
        <>
            <div className="row mb-4">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-10">
                            <h5>Clients</h5>
                        </div>
                        <div className="col-md-2 text-right row">
                            <div className="col-md-7">
                                <input
                                    type="text"
                                    className="form-control mt-1"
                                    placeholder="Search client here..."
                                    value={props.ClientSearch}
                                    onChange={(e) => props.setClientSearch(e.target.value)}
                                />
                            </div>

                            <div className="col-md-5" style={{ display: "flex" }}>
                                <div className="position-badge" style={{ display: "flex", textAlign:"center" }} onClick={() => {
                                    props.setBsDownAlertFlag(!props.BsDownAlertFlag);
                                }}>
                                    {props.AlertBadgeCount ? <div className="badge green">{props.AlertBadgeCount}</div>
                                        : null}

                                    <i className="bx bx-bell icon box-icon-style" style={{ fontSize: "33px" }} ></i>
                                </div>
                                <i
                                    className="bx bx-notification box-icon-style"
                                    style={{ fontSize: "30px" }}
                                    onClick={() => {
                                        props.setImpactedDeviceDownAlertFlag(!props.ImpactedDeviceDownAlertFlag);
                                    }}
                                ></i>

                                <i
                                    className="bx bx-bell-plus mx-2 box-icon-style"
                                    style={{ fontSize: "30px" }}
                                    onClick={() => {
                                        props.setBaseStationSpecificFlag(!props.BaseStationSpecificFlag);
                                    }}
                                ></i>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="row">

                                {props.ClientWiseBaseStationData.map((item, index) => {
                                    item.ClientName = item.ClientName ? item.ClientName : "-";
                                    return item.ClientName.toLowerCase().includes(
                                        props.ClientSearch.trim().toLowerCase()
                                    ) ? (
                                        <div key={index} className="col-sm-12">
                                            <div
                                                className="row dashboard-client-card cursor-pointer"
                                                onClick={() => {
                                                    // props.setClientId(item.ClientId);
                                                    // props.setClientCountryId();
                                                    // props.setClientName(item.ClientName);
                                                }}
                                                style={
                                                    props.ClientId === item.ClientId
                                                        ? { border: "1px solid gray", padding: "1%" }
                                                        : { padding: "1%" }
                                                }
                                            >
                                                <div className="col-sm-11 mb-2 d-flex" onClick={() => {
                                                    props.setClientId(item.ClientId);
                                                    props.setClientCountryId();
                                                    props.setClientName(item.ClientName);
                                                    props.setClientLogo(item.ClientLogo)
                                                }} style={{
                                                    justifyContent: "center"
                                                }}>
                                                    <img
                                                        src={item.ClientLogo ? item.ClientLogo : DefaultLogo}
                                                        className="user-img mx-2"
                                                        alt="Client Logo"
                                                        style={{
                                                            // width: "2.3%",
                                                        }}
                                                    />
                                                    <span className='mx-1'><h4 className='mt-1'>{item.ClientName}</h4></span>

                                                </div>
                                                <div className="col-sm-1">
                                                    {UserGroup.Id < 3 ?
                                                        <i
                                                            className="bx bx-cog box-icon-style"
                                                            style={{ fontSize: "25px" }}
                                                            onClick={() => {
                                                                props.setCronStatusClientId(item.ClientId);
                                                                props.setSelectedCronStatusPage("client")
                                                            }}
                                                        ></i> : null}
                                                </div>

                                                <div className='col-sm-6 row'>
                                                    <div className="col-sm-6 mb-2" style={{
                                                        verticalAlign: "middle",
                                                    }}>
                                                        <span style={{
                                                            fontWeight: "bold"
                                                        }}><b>BIT State</b></span>
                                                        <BitSunburstChart setClientLogo={props.setClientLogo} item={item} Selected="client" setClientId={props.setClientId} setClientCountryId={props.setClientCountryId} setClientName={props.setClientName} />
                                                    </div>

                                                    <div className="col-sm-6">
                                                        <span style={{
                                                            fontWeight: "bold"
                                                        }}><b>COMM State</b></span>
                                                        <CommSunburstChart setClientLogo={props.setClientLogo} item={item} Selected="client" setClientId={props.setClientId} setClientCountryId={props.setClientCountryId} setClientName={props.setClientName} />
                                                    </div>
                                                    <div>

                                                        <span className='mx-4' style={{
                                                            fontWeight: "bold"
                                                        }}>
                                                            <span style={{ height: '16px', width: '16px', display: 'inline-block', marginRight: '5px', backgroundColor: '#00b500' }}></span>
                                                            OK
                                                        </span>

                                                        <span className='mx-4' style={{
                                                            fontWeight: "bold"
                                                        }}>
                                                            <span style={{ height: '16px', width: '16px', display: 'inline-block', marginRight: '5px', backgroundColor: '#fd6666' }}></span>
                                                            KO
                                                        </span>

                                                        <span className='mx-4' style={{
                                                            fontWeight: "bold"
                                                        }}>
                                                            <span style={{ height: '16px', width: '16px', display: 'inline-block', marginRight: '5px', backgroundColor: 'red' }}></span>
                                                            NO
                                                        </span>

                                                        <span className='mx-4' style={{
                                                            fontWeight: "bold"
                                                        }}>
                                                            <span style={{ height: '16px', width: '16px', display: 'inline-block', marginRight: '5px', backgroundColor: '#df8c29' }}></span>
                                                            WARN
                                                        </span>
                                                    </div>
                                                </div>

                                                {/* tickets */}
                                               
                                                    <>
                                                        {/* Sigfox Tickets */}
                                                        <div className='col-sm-2'>
                                                            <div className="col-sm-12" >
                                                                <span style={{
                                                                    fontWeight: "bold",
                                                                    fontSize: "15px"
                                                                }}><b>Sigfox Tickets</b></span>
                                                            </div>
                                                            <Link to={"/tickets?ClientId=" + item.ClientId}>
                                                                <div className="col-sm-12">
                                                                    <div className="row dashboard-ticket-card mx-1">
                                                                        <TicketCharts item={item} />
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>

                                                        {/* Cabisense Tickets */}
                                                        <div className='col-sm-2'>

                                                            <div className="col-sm-12">
                                                                <span style={{
                                                                    fontWeight: "bold",
                                                                    fontSize: "15px"
                                                                }}><b>Cabisense Tickets</b></span>
                                                            </div>

                                                            <Link to={"/cabisense_tickets?ClientId=" + item.ClientId} >
                                                                <div className="col-sm-12">
                                                                    <div className="row dashboard-ticket-card mx-1">
                                                                        <CabisenseTicketCharts item={item} />
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>

                                                        {/* Meraki Tickets */}
                                                        <div className='col-sm-2'>

                                                            <div className="col-sm-12">
                                                                <span style={{
                                                                    fontWeight: "bold",
                                                                    fontSize: "15px"
                                                                }}><b>Meraki Tickets</b></span>
                                                            </div>

                                                            <Link to={"/meraki_tickets?ClientId=" + item.ClientId} >
                                                                <div className="col-sm-12">
                                                                    <div className="row dashboard-ticket-card mx-1">
                                                                        <MerakiTicketCharts item={item} />
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </>
                                                   
                                            </div>
                                        </div>
                                    ) : null;
                                })}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClientDetails