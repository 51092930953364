import React from 'react'
import { Link } from 'react-router-dom';

const ClientPage = (props) => {
  console.log('✌️props --->', props);
  console.log(props.CabisenseTicketModuleAccess !== "none")
  return (
    <div className="row mb-4">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-10">
            <h5>Clients</h5>
          </div>
          <div className="col-md-2 text-right row">
            <div className="col-md-7">
              <input
                type="text"
                className="form-control mt-1"
                placeholder="Search client here..."
                value={props.ClientSearch}
                onChange={(e) => props.setClientSearch(e.target.value)}
              />
            </div>

            <div className="col-md-5" style={{ display: "flex" }}>
              <div className="position-badge" style={{ display: "flex", textAlign: "center" }} onClick={() => {
                props.setBsDownAlertFlag(!props.BsDownAlertFlag);
              }}>
                {props.AlertBadgeCount ? <div className="badge green">{props.AlertBadgeCount}</div>
                  : null}

                <i className="bx bx-bell icon box-icon-style" style={{ fontSize: "33px", }} ></i>
              </div>
              <i
                className="bx bx-notification box-icon-style"
                style={{ fontSize: "30px" }}
                onClick={() => {
                  props.setImpactedDeviceDownAlertFlag(!props.ImpactedDeviceDownAlertFlag);
                }}
              ></i>

              <i
                className="bx bx-bell-plus mx-2 box-icon-style"
                style={{ fontSize: "30px" }}
                onClick={() => {
                  props.setBaseStationSpecificFlag(!props.BaseStationSpecificFlag);
                }}
              ></i>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="row">
              {props.ClientWiseBaseStationData.map((item, index) => {
                item.ClientName = item.ClientName ? item.ClientName : "-";
                return item.ClientName.toLowerCase().includes(
                  props.ClientSearch.trim().toLowerCase()
                ) ? (
                  <div key={index} className="col-sm-6">
                    <div
                      className="row dashboard-client-card cursor-pointer"

                      style={
                        props.ClientId === item.ClientId
                          ? { border: "1px solid gray" }
                          : {}
                      }
                    >
                      <div className="col-sm-11 mb-2" style={{ marginRight: "-30px" }}>
                        <h4>{item.ClientName}</h4>

                      </div>
                      <div className="col-sm-1 mb-2" >
                        {props.UserGroup.Id < 3 ? <i
                          className="bx bx-cog box-icon-style"
                          style={{ fontSize: "25px" }}
                          onClick={() => {
                            props.setCronStatusClientId(item.ClientId);
                            props.setSelectedCronStatusPage("client")
                          }}
                        ></i> : null}
                      </div>
                      <div className="row" onClick={() => {
                        props.setClientId(item.ClientId);
                        props.setClientCountryId();
                        props.setClientName(item.ClientName);
                      }}>
                        <div className="col-sm-6 mb-2" style={{
                          verticalAlign: "middle",
                          display: "flex",
                        }}>
                          <p style={{
                            margin: "auto",
                            fontSize: "20px",
                            fontWeight: "bold",
                            verticalAlign: "middle",
                            textDecoration: "underline"
                          }}>BIT State</p>
                        </div>

                        <div className="col-sm-6">
                          <p style={{
                            margin: "auto",
                            fontSize: "20px",
                            fontWeight: "bold",
                            verticalAlign: "middle",
                            textDecoration: "underline"
                          }}>{parseInt(item.OKStateCount) + parseInt(item.KOStateCount) + parseInt(item.NOStateCount) + parseInt(item.WARNStateCount)}</p>
                        </div>
                        <div className="col-sm-3">
                          <h5>{item.OKStateCount}</h5>
                          <label>OK</label>
                        </div>
                        <div className="col-sm-3">
                          <h5>{item.KOStateCount}</h5>
                          <label>KO</label>
                        </div>
                        <div className="col-sm-3">
                          <h5>{item.NOStateCount}</h5>
                          <label>NO</label>
                        </div>
                        <div className="col-sm-3">
                          <h5>{item.WARNStateCount}</h5>
                          <label>WARN</label>
                        </div>

                        {/* other state */}
                        <hr />

                        <div className="col-sm-6 mb-2" style={{
                          verticalAlign: "middle",
                          display: "flex",
                        }}>
                          <p style={{
                            margin: "auto",
                            fontSize: "20px",
                            fontWeight: "bold",
                            verticalAlign: "middle",
                            textDecoration: "underline"
                          }}>Communication State</p>
                        </div>
                        <div className="col-sm-6">
                          <p style={{
                            margin: "auto",
                            fontSize: "20px",
                            fontWeight: "bold",
                            verticalAlign: "middle",
                            textDecoration: "underline"
                          }}>{parseInt(item.OKCommStateCount) + parseInt(item.KOCommStateCount) + parseInt(item.NOCommStateCount) + parseInt(item.WARNCommStateCount)}</p>
                        </div>
                        <div className="col-sm-3">
                          <h5>{item.OKCommStateCount}</h5>
                          <label>OK</label>
                        </div>
                        <div className="col-sm-3">
                          <h5>{item.KOCommStateCount}</h5>
                          <label>KO</label>
                        </div>
                        <div className="col-sm-3">
                          <h5>{item.NOCommStateCount}</h5>
                          <label>NO</label>
                        </div>
                        <div className="col-sm-3">
                          <h5>{item.WARNCommStateCount}</h5>
                          <label>WARN</label>
                        </div>
                      </div>
                      <hr />
                      {/* end other state */}
                      {/* tickets */}

                      {props.TicketModuleAccess !== "none" ?
                        <>
                          <div className="col-sm-12" style={{ marginTop: "-6px" }}>
                            <span><b>Sigfox Tickets</b></span>
                          </div>
                          <Link to={"/tickets?ClientId=" + item.ClientId} title="Show Tickets">
                            <div className="col-sm-12">
                              <div className="d-flex justify-content-sm-between dashboard-ticket-card" style={{ overflow: "auto", gap: "10px" }}>
                                <div className="total-ticket">
                                  {item.TotalTickets}<br />Total Tickets
                                </div>
                                <div className="ticket-resolve">
                                  {item.ResolvedTickets}<br />Resolved Tickets
                                </div>
                                <div className="ticket-open">
                                  {item.OpenTickets}<br />Open Tickets
                                </div>
                                <div className="ticket-close">
                                  {item.ClosedTickets}<br />Closed Tickets
                                </div>
                                <div className="ticket-incident_dispatch">
                                  {item.IncidentDispatchedTickets}<br />Incident Dispatch
                                </div>
                                <div className="ticket-deferred">
                                  {item.DeferredTickets}<br />Deferred
                                </div>
                                <div className="ticket-power-confirmation">
                                  {item.PowerConfirmationTickets}<br />Power Confirmation
                                </div>

                              </div>
                            </div>
                          </Link>
                        </> : null}
                      {props.CabisenseTicketModuleAccess !== "none" ?
                        <>
                          <div className="col-sm-12 mt-2">
                            <span><b>Cabisense Tickets</b></span>
                          </div>

                          <Link to={"/cabisense_tickets?ClientId=" + item.ClientId} title="Show Tickets">
                            <div className="col-sm-12">
                              <div className="d-flex justify-content-sm-between dashboard-ticket-card" style={{ overflow: "auto", gap: "10px" }}>
                                <div className="total-ticket">
                                  {item.TotalCabisenseTickets}<br />Total Tickets
                                </div>
                                {/* <div className="ticket-resolve">
                              {item.ResolvedCabisenseTickets}<br />Resolved Tickets
                            </div> */}
                                <div className="ticket-open">
                                  {item.OpenCabisenseTickets}<br />Open Tickets
                                </div>
                                <div className="ticket-close">
                                  {item.ClosedCabisenseTickets}<br />Closed Tickets
                                </div>
                                <div className="ticket-incident_dispatch">
                                  {item.IncidentDispatchedCabisenseTickets}<br />Incident Dispatch
                                </div>
                                <div className="ticket-deferred">
                                  {item.DeferredCabisenseTickets}<br />Deferred
                                </div>
                                {/* <div className="ticket-power-confirmation">
                                {item.PowerConfirmationCabisenseTickets}<br />Power Confirmation
                              </div> */}
                              </div>
                            </div>
                          </Link>
                        </>
                        : null
                      }

                      {
                        props.MerakiTicketModuleAccess !== "none" ?
                          <>
                            {/* Meraki Ticket */}
                            <div className="col-sm-12 mt-2">
                              <span><b>Meraki Tickets</b></span>
                            </div>

                            <Link to={"/meraki_tickets?ClientId=" + item.ClientId} title="Show Tickets">
                              <div className="col-sm-12">
                                <div className="d-flex justify-content-sm-between dashboard-ticket-card" style={{ overflow: "auto", gap: "10px" }}>
                                  <div className="total-ticket">
                                    {item.TotalMerakiTickets}<br />Total Tickets
                                  </div>
                                  <div className="ticket-resolve">
                                    {item.ResolvedMerakiTickets}<br />Resolved Tickets
                                  </div>
                                  <div className="ticket-open">
                                    {item.OpenMerakiTickets}<br />Open Tickets
                                  </div>
                                  <div className="ticket-close">
                                    {item.ClosedMerakiTickets}<br />Closed Tickets
                                  </div>
                                  <div className="ticket-incident_dispatch">
                                    {item.IncidentDispatchedMerakiTickets}<br />Incident Dispatch
                                  </div>
                                  <div className="ticket-deferred">
                                    {item.DeferredMerakiTickets}<br />Deferred
                                  </div>
                                  {/* <div className="ticket-power-confirmation">
                                {item.PowerConfirmationMerakiTickets}<br />Power Confirmation
                              </div> */}
                                </div>
                              </div>
                            </Link>
                          </>
                          : null
                      }
                      {/* Meraki Ticket End */}

                    </div>
                  </div>
                ) : null;
              })}
            </div>
          </div>
          {/* Alert notifications block */}


        </div>
      </div>
    </div>
  )
}

export default ClientPage