import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from "react-toastify";
import { Link } from 'react-router-dom';
import axios from "../../axios";
import Loader from '../../Components/Loader';
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import Alert from "../../Components/Alert";

const MultiBitList = () => {
  const location = useLocation();
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  const [ModuleData, setModuleData] = useState([])
  const [loader, setLoader] = useState("")
  const [DeleteUserId, setDeleteUserId] = useState("");
  const [ServiceCall, setServiceCall] = useState(true);
  const [error, setError] = useState("");
  const [ModuleAccess, setModuleAccess] = useState("");

  useEffect(() => {
    if (success) {
      toast.success(success);
      setSuccess("");
    }
    return () => { };
  }, [success]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError("");
    }
    return () => { };
  }, [error]);

  useEffect(() => {
    if (location.state) {
      setSuccess(location.state);
      navigate("/multi_bit_incident_code_config", {
        state: null,
      });
    }
  }, [location, navigate]);

  const AlertResponse = (response) => {
    if (response === true) {
      setLoader(true);
      axios
        .delete("delete_multi_bit_config/" + DeleteUserId)
        .then((response) => {
          setServiceCall(true);
          setDeleteUserId("");
          setSuccess("Category deleted successfully");
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    } else {
      setDeleteUserId("");
    }
  };

  useEffect(() => {
    if (ServiceCall) {
      setLoader(true);
      axios
        .get("get_all_multi_bit_config")
        .then((response) => {
          setModuleData(response.data.data.data);
          setModuleAccess(response.data.data.ModuleAccess)
          // setClients(response.data.data);
          // setClient(response.data.data.Id);
          setLoader(false);
          setServiceCall(false)
        })
        .catch(() => {
          setLoader(false);
        });
    }

  }, [ServiceCall])

  return (
    <>
      <ToastContainer theme="colored" />
      {loader ? <Loader /> : null}

      {DeleteUserId ?
        <Alert
          message="Are you sure, you want to delete this user?"
          AlertResponse={AlertResponse}
        />
        :
        ""
      }

      {/* {loader ? <Loader height={LoaderHeight} /> : null} */}
      <div className="row">
        <div className="col-md-8">
          <h6 className="mb-0">Multi Bit Incident Code List</h6>
        </div>

      </div>
      <hr />

      <div className="card">
        <div className="card-body horizontal-scroll">
          <div className="row">
            {
              ModuleAccess === "write" ?
                <div className="col-md-12">
                  <Link to="/add_multi_bit_incident_code_config" ><button
                    type="submit"
                    className="btn btn-primary px-5"
                    style={{ float: "right", marginLeft: "10px" }}
                  >
                    Add
                  </button></Link>
                </div>
                : null
            }

          </div>

          <div className="row">
            <div className="col-md-12">
              <table className="table mb-0 table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Incident Codes</th>
                    {
                      ModuleAccess === "write" ?
                        <th>Actions</th>
                        : null
                    }

                  </tr>
                </thead>
                <tbody>
                  {ModuleData.length > 0 ?
                    ModuleData.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.IncidentCode}</td>
                          <td>{item.GetIncidentCode}</td>
                          {
                            ModuleAccess === "write" ?
                              <td className="action-buttons">
                                <i
                                  className="bx bx-trash text-danger"
                                  title="Delete"
                                  onClick={() => setDeleteUserId(item.Id)}
                                ></i>
                                <Link to={"/update_multi_bit_incident_code_config/" + item.Id}>
                                  <i className="bx bx-pencil" title="Edit"></i>
                                </Link>
                              </td> : null}
                        </tr>
                      );
                    })
                    :
                    <tr>
                      <td
                        colSpan={5}
                        style={{ textAlign: "center" }}>
                        <b>No Record Found</b>
                      </td>
                    </tr>}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MultiBitList