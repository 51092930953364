import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../axios";
import Loader from "../../Components/Loader";
import Alert from "../../Components/Alert";
import DefaultLogo from "../../Images/foxilogic-disable.png"
import { CUSTOM_CLIENT_SELECT } from '../../Custom';

const ViewFlutteringConfig = () => {
    var UserGroup = JSON.parse(localStorage.getItem("UserGroup"));
    var ClientId = JSON.parse(localStorage.getItem("ClientId"));
    const [loader, setLoader] = useState(false);
    const [Clients, setClients] = useState([]);
    const [ClientID, setClientID] = useState(ClientId || "-1");
    const [success, setSuccess] = useState("");
    const [ModuleData, setModuleData] = useState([])
    const [ApplyAllAlert, setApplyAllAlert] = useState("");
    const [CoolingPeriodError, setCoolingPeriodError] = useState("");
    const [NoOfTicketsError, setNoOfTicketsError] = useState("");
    const [WaitingPeriodError, setWaitingPeriodError] = useState("");
    const [ModuleAccess, setModuleAccess] = useState("");

    const getClientValue = () => {
        if (ClientID) {
            for (var i = 0; i < Clients.length; i++) {
                if (Clients[i].Id === parseInt(ClientID)) {
                    return [{ value: Clients[i].Id, label: Clients[i].Name, image: Clients[i].image }];
                }
            }
        }
    }

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess("");
        }
        return () => { };
    }, [success]);

    const OnchangeClient = (selected) => {
        setClientID(selected.Id)
    }

    useEffect(() => {
        if (UserGroup.Id === 1 || UserGroup.Id === 2) {
            setLoader(true);
            axios
                .get("clients")
                .then((response) => {
                    var TempData = response.data.data.data;
                    var arr = [];
                    for (var i = 0; i < TempData.length; i++) {
                        if (i === 0 && (UserGroup.Id === 1 || UserGroup.Id === 2)) {
                            var default_obj = {};
                            default_obj["Id"] = -1;
                            default_obj["Name"] = "Default Setting";
                            default_obj["label"] = "Default Setting";
                            default_obj["value"] = -1;
                            default_obj["image"] = DefaultLogo;
                            arr.push(default_obj)
                        }

                        var obj = TempData[i]
                        obj["label"] = TempData[i].Name;
                        obj["value"] = TempData[i].Id;
                        obj["image"] = TempData[i].ClientLogo ? TempData[i].ClientLogo : DefaultLogo;
                        arr.push(obj)
                    }
                    setClients(arr)
                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                });
        }
    }, [UserGroup.Id]);

    useEffect(() => {
        if (ClientID) {
            setLoader(true)
            axios.get("/get_fluttering_config?ClientId=" + ClientID)
                .then((response) => {
                    setModuleData(response.data.data.data)
                    setModuleAccess(response.data.data.ModuleAccess)
                    setLoader(false)
                })
                .catch((error) => {
                    setLoader(false)
                })
        }
    }, [ClientID])

    const validate = () => {
        let flag = true;

        let WaitingPeriodError = []
        let NoOfTicketsError = []
        let CoolingPeriodError = []

        for (var i = 0; i < ModuleData.length; i++) {
            ModuleData[i].FlutteringCoolingPeriod = (ModuleData[i].FlutteringCoolingPeriod !== null && ModuleData[i].FlutteringCoolingPeriod !== '') ? parseInt(ModuleData[i].FlutteringCoolingPeriod) : ''
            ModuleData[i].FlutteringNoOfTickets = (ModuleData[i].FlutteringNoOfTickets !== null && ModuleData[i].FlutteringNoOfTickets !== '') ? parseInt(ModuleData[i].FlutteringNoOfTickets) : ''
            ModuleData[i].FlutteringWaitingPeriod = (ModuleData[i].FlutteringWaitingPeriod !== null && ModuleData[i].FlutteringWaitingPeriod !== '') ? parseInt(ModuleData[i].FlutteringWaitingPeriod) : ''

            if (ModuleData[i].FlutteringCoolingPeriod === "") {
                flag = false;
                CoolingPeriodError[i] = "This field is required"
            }

            if (ModuleData[i].FlutteringNoOfTickets === "") {
                flag = false;
                NoOfTicketsError[i] = "This field is required"
            }

            if (ModuleData[i].FlutteringWaitingPeriod === "") {
                flag = false;
                WaitingPeriodError[i] = "This field is required"
            }
        }

        setCoolingPeriodError(CoolingPeriodError)
        setNoOfTicketsError(NoOfTicketsError)
        setWaitingPeriodError(WaitingPeriodError)

        return flag;
    }

    const submitSiteStatus = (type) => {
        const flag = validate();

        if (flag) {
            setLoader(true);
            const param = {
                ClientId: ClientID,
                SubmitType: type,
                FlutteringConfig: ModuleData
            }
            axios.put("update_fluttering_config", param)
                .then((response) => {
                    setLoader(false)
                    setSuccess(response.data.status.message)
                    setModuleData(response.data.data)
                })
                .catch((error) => {
                    setLoader(false)
                })
        }
    }

    const AlertResponse = (Result) => {
        if (Result) {
            submitSiteStatus("SaveToAll")
        }
        setApplyAllAlert('')
    }

    const handleWaitingPeriod = (index, value) => {
        var ModuleDataTemp = JSON.parse(JSON.stringify(ModuleData));
        ModuleDataTemp[index].FlutteringWaitingPeriod = value;
        setModuleData(ModuleDataTemp);
    };

    const handleNoOfTickets = (index, value) => {
        var ModuleDataTemp = JSON.parse(JSON.stringify(ModuleData));
        ModuleDataTemp[index].FlutteringNoOfTickets = value;
        setModuleData(ModuleDataTemp);
    };

    const handleCoolingPeriod = (index, value) => {
        var ModuleDataTemp = JSON.parse(JSON.stringify(ModuleData));
        ModuleDataTemp[index].FlutteringCoolingPeriod = value;
        setModuleData(ModuleDataTemp);
    };

    return (
        <div>
            {ApplyAllAlert ? <Alert message={ApplyAllAlert} AlertResponse={AlertResponse} /> : null}
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            <div className="row">
                <div className="col-md-8">
                    <h6 className="mb-0">Sigfox Fluttering Config List</h6>
                </div>
            </div>
            <hr />

            <div className="card">
                <div className="card-body horizontal-scroll">
                    <div className="row">
                        <div className="col-md-6">
                            {UserGroup.Id === 1 || UserGroup.Id === 2 ? (
                                <>
                                    {CUSTOM_CLIENT_SELECT({ ClientListOptions: Clients, Value: getClientValue(), OnchangeClient: OnchangeClient })}
                                </>
                            ) : null}
                        </div>

                        {
                            ModuleAccess === 'write' ?
                                <div className="col-md-6">
                                    <button
                                        type="submit"
                                        className="btn btn-primary px-5"
                                        style={{ marginTop: "20px", float: "right", marginLeft: "10px" }}
                                        onClick={() => submitSiteStatus("Save")}
                                    >
                                        Save
                                    </button>
                                    &nbsp;
                                    {((UserGroup.Id === 1 || UserGroup.Id === 2) && ClientID === '-1') ?
                                        <button
                                            type="submit"
                                            className="btn btn-primary px-5"
                                            style={{ marginTop: "20px", float: "right" }}
                                            onClick={() => setApplyAllAlert('Are you sure you want to apply all?')}
                                        >
                                            Apply to all
                                        </button>
                                        : null}
                                </div>
                                : null
                        }


                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <table
                                className="table mb-0 table-striped text-center"
                                style={{ verticalAlign: "middle" }}
                            >
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th style={{ textAlign: "left" }}>Test Type</th>
                                        <th style={{ textAlign: "left" }}>Incident Code</th>
                                        <th style={{ textAlign: "left" }}>Fluttering Waiting Period</th>
                                        <th style={{ textAlign: "left" }}>Fluttering No. Of Tickets</th>
                                        <th style={{ textAlign: "left" }}>Fluttering Cooling Period</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ModuleData.length > 0 ?
                                        ModuleData.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td style={{ textAlign: "left" }}> {item.TestType} </td>
                                                    <td style={{ textAlign: "left" }}> {item.IncidentCode} </td>
                                                    <td style={{ textAlign: "left" }}>
                                                        <input type="number" className="form-control"
                                                            value={item.FlutteringWaitingPeriod !== null ? item.FlutteringWaitingPeriod : ""}
                                                            min="0"
                                                            onChange={(e) => handleWaitingPeriod(index, e.target.value)}
                                                        />
                                                        <span className="text-danger" style={{ color: "red" }}>{WaitingPeriodError[index] ? WaitingPeriodError[index] : null}</span>
                                                    </td>
                                                    <td style={{ textAlign: "left", display: "flex" }}>
                                                        {
                                                            parseInt(item.UsedType) === 1 ?
                                                                <label className='p-2 fw-bold'>
                                                                    No. Of Warns
                                                                </label>
                                                                : null
                                                        }
                                                        <input type="number" className="form-control"
                                                            value={item.FlutteringNoOfTickets !== null ? item.FlutteringNoOfTickets : ""}
                                                            min="0"
                                                            onChange={(e) => handleNoOfTickets(index, e.target.value)}
                                                        />
                                                        <span className="text-danger" style={{ color: "red" }}>{NoOfTicketsError[index] ? NoOfTicketsError[index] : null}</span>
                                                    </td>
                                                    <td style={{ textAlign: "left" }}>
                                                        <input type="number" className="form-control"
                                                            value={item.FlutteringCoolingPeriod !== null ? item.FlutteringCoolingPeriod : ""}
                                                            min="0"
                                                            onChange={(e) => handleCoolingPeriod(index, e.target.value)}
                                                        />
                                                        <span className="text-danger" style={{ color: "red" }}>{CoolingPeriodError[index] ? CoolingPeriodError[index] : null}</span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr>
                                            <td
                                                colSpan={6}
                                                style={{ textAlign: "center" }}>
                                                <b>No Record Found</b>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {
                        ModuleAccess === 'write' ?
                            <div className="row">
                                <div className="col-md-12">
                                    <button
                                        type="submit"
                                        className="btn btn-primary px-5"
                                        style={{ marginTop: "20px", float: "right", marginLeft: "10px" }}
                                        onClick={() => submitSiteStatus("Save")}
                                    >
                                        Save
                                    </button>
                                    {((UserGroup.Id === 1 || UserGroup.Id === 2) && ClientID === '-1') ?
                                        <button
                                            type="submit"
                                            className="btn btn-primary px-5"
                                            style={{ marginTop: "20px", float: "right" }}
                                            onClick={() => submitSiteStatus("SaveToAll")}
                                        >
                                            Apply to all
                                        </button>
                                        : null}
                                </div>

                            </div>
                            : null}
                </div>
            </div>

        </div>
    )
}

export default ViewFlutteringConfig