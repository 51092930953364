import React, { useState, useEffect } from "react";
import axios from "../../axios";
import Loader from "../../Components/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router";
import { useNavigate, useParams } from "react-router-dom";
import MerakiEmailBodyView from "./MerakiEmailBodyView";
import Pagination from "../../Components/Pagination";
import TimeZone from "../../TimeZone";
import Alert from "../../Components/Alert";
import MerakiFaultDetails from "./MerakiFaultDetails";
import MerakiUpdateFault from "./MerakiUpdateFault";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import MerakiAddDiferDetails from "./MerakiAddDiferDetails";
import MerakiDiferDetails from "./MerakiDiferDetails";
import { OPENGROUPTICKETSTATUS, OPENGROUPTICKETSTATUSARR } from "../../Custom";
import ManualMerakiTicketGenaration from "./ManualMerakiTicketGenaration";
import { GENERATEDBYLISTOFTICKET, TICKET_STATUS_OPEN, MERAKI_DEFERRED_CONDITION, MERAKI_UNDEFERRED_CONDITION, TICKET_STATUS_CLOSED } from "../../Custom";
import Log from "./Log";
import AddCustomSp from "../../Components/AddCustomSp";
import TicketDescription from "../../Components/TicketDescription";

const Option = (props) => {
  return (
    <div className="custom-select-option">
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        &nbsp;
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

function MerakiTicketsList(props) {
  const { CountryID } = useParams();
  const UserGroup = JSON.parse(localStorage.getItem("UserGroup"));

  const navigate = useNavigate();
  const location = useLocation();
  var url = new URL(window.location.href);
  const page = url.searchParams.get('PageNo') || 1
  const limit = url.searchParams.get('Limit') || 500
  const search = url.searchParams.get('Search') || ''
  const maintenance = url.searchParams.get('Maintenance') || "";
  const [NoOfRecords, setNoOfRecords] = useState(0);
  const [ModuleData, setModuleData] = useState([]);
  const [ServiceCall, setServiceCall] = useState(location.state ? false : true);
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState("");
  const [TicketSelected, setTicketSelected] = useState("");
  const [searchInput, setSearchInput] = useState(search)
  const [searched, setSearched] = useState(false)

  var assign_value = false;
  if (url.searchParams.get('ShowAssignedTickets') === "1") {
    assign_value = true;
  }
  const [AssignTickets, setAssignTickets] = useState(assign_value)
  const [TicketStatusList, setTicketStatusList] = useState([])

  const StatusArr = [];
  const Status = url.searchParams.get('Status');

  if (Status) {
    const StatusValues = Status.split("-");
    StatusValues.forEach(statusValue => {
      const matchedStatus = TicketStatusList.find(ticketStatus => ticketStatus.value === statusValue);
      if (matchedStatus) {
        StatusArr.push(matchedStatus);
      }
    });
  }

  const [TicketStatus, setTicketStatus] = useState(StatusArr)
  const [IncidentCodeList, setIncidentCodeList] = useState([]);
  const [MaintenanceList, setMaintenanceList] = useState([])
  const [Maintenance, setMaintenance] = useState(maintenance)

  var UrlIncidentCode = []
  if (url.searchParams.get('IncidentCode')) {
    var Codes = url.searchParams.get('IncidentCode').split(",");
    for (var j = 0; j < Codes.length; j++) {
      for (var i = 0; i < IncidentCodeList.length; i++) {
        if (Codes[j] === IncidentCodeList[i].IncidentCode) {
          var obj = IncidentCodeList[i]
          UrlIncidentCode.push(obj);
        }
      }
    }
  }

  const [IncidentCode, setIncidentCode] = useState(UrlIncidentCode)
  const [TicketCategory, setTicketCategory] = useState(url.searchParams.get('TicketCategory') || "")
  const [SiteStatusCode, setSiteStatusCode] = useState(url.searchParams.get('SiteStatus') || "")
  const [SiteStatusCodeList, setSiteStatusCodeList] = useState([])
  const [TicketCategoryList, setTicketCategoryList] = useState([])
  const [TotalTicketCount, setTotalTicketCount] = useState("")
  const [TicketsModuleAccess, setTicketsModuleAccess] = useState("");
  const [Site, setSite] = useState("");
  const [FilterTicketId, setFilterTicketId] = useState(url.searchParams.get('TicketId') || '');
  const [BaseStationId, setBaseStationId] = useState(url.searchParams.get('BasestationId') || '');
  const [SiteName, setSiteName] = useState(url.searchParams.get('SiteName') || '');
  const [EmailData, setEmailData] = useState({});
  const [EmailBodyFlag, setEmailBodyFlag] = useState(false);
  const [CreateFaultTicketId, setCreateFaultTicketId] = useState("");
  const [CloseFaultTicketId, setCloseFaultTicketId] = useState("");
  const [FaultDetailsId, setFaultDetailsId] = useState("");
  const [UpdateFaultId, setUpdateFaultId] = useState("");
  const [ClickUpdateFaultId, setClickUpdateFaultId] = useState("");
  const [DeferTicketDate, setDeferTicketDate] = useState({});
  const [DiferDetailsId, setDiferDetailsId] = useState("");
  const [UndiferredTicketId, setUndiferredTicketId] = useState("");
  const [ManualTicketFlag, setManualTicketFlag] = useState(false);
  const GeneratedByList = GENERATEDBYLISTOFTICKET;
  const [GeneratedBy, setGeneratedBy] = useState(url.searchParams.get('GeneratedBy') || "");
  const [RegionList, setRegionList] = useState([])
  const [Region, setRegion] = useState(url.searchParams.get('Region') || "")
  const [TicketLogId, setTicketLogId] = useState({})

  // CUSTOM SP
  const [CustomSpList, setCustomSpList] = useState([])
  const [CustomSpData, setCustomSpData] = useState({})
  const customsp = url.searchParams.get('CustomSp') || "0";
  const [CustomSp, setCustomSp] = useState(customsp);
  const [CountryEnabledCustomSp, setCountryEnabledCustomSp] = useState([])
  const [PostEventTicketId, setPostEventTicketId] = useState("")
  const [TicketDescriptionData, setTicketDescriptionData] = useState({})

  useEffect(() => {
    if (TicketStatusList.length > 0 && TicketStatus.length === 0) {
      var StatusArr = [];
      for (var i = 0; i < TicketStatusList.length; i++) {
        if (TicketStatusList[i].value === TICKET_STATUS_OPEN) {
          var obj = TicketStatusList[i]
          StatusArr.push(obj);
        }
      }
      setTicketStatus(StatusArr);
    }
  }, [TicketStatus, TicketStatusList])

  useEffect(() => {
    if (location.state) {
      if (location.state.tickete_id) {
        setFilterTicketId(location.state.tickete_id)
      }
      if (location.state.siteId) {
        setTicketStatus(OPENGROUPTICKETSTATUS)
        setSite(location.state.siteId)
      }
      if (location.state.BaseStationId) {
        setTicketStatus(OPENGROUPTICKETSTATUS)
        setBaseStationId(location.state.BaseStationId)
      }
      if (location.state.OutageMngtSite) {
        setTicketStatus(OPENGROUPTICKETSTATUS)
        setSiteName(location.state.OutageMngtSite);
      }
      setSuccess(location.state);
      navigate("/meraki-tickets-list/" + CountryID, {
        state: null,
      });
    }
  }, [location, CountryID, navigate]);

  useEffect(() => {
    if (success) {
      toast.success(success);
      setSuccess("");
    }
    return () => { };
  }, [success]);

  useEffect(() => {
    setServiceCall(true);
  }, [limit, page, search]);

  useEffect(() => {
    if (CountryID) {
      setLoader(true)
      axios.get("masters?ClientCountryId=" + CountryID)
        .then((response) => {
          const TempTicketStatus = response.data.data.TicketStatus;
          const TicketStatusArr = TempTicketStatus.map(status => ({ value: status, label: status }));
          TicketStatusArr.unshift({ value: "Select All", label: "Select All" });
          setTicketStatusList(TicketStatusArr);
          setSiteStatusCodeList(response.data.data.SiteStatus)
          const MerakiIncidentCodes = response.data.data.MerakiIncidentCodes;
          const Arr = MerakiIncidentCodes.map(item => ({
            ...item,
            value: item.IncidentCode,
            label: item.IncidentCode
          }));
          setIncidentCodeList(Arr);
          setTicketCategoryList(response.data.data.TicketCategory)
          setMaintenanceList(response.data.data.aSiteMaintenanceNames)
          setRegionList(response.data.data.SigfoxSiteRegions)
          setCustomSpList(response.data.data.CountryWiseCustomSpList)
          setCountryEnabledCustomSp(response.data.data.CountryEnabledCustomSp)

          setLoader(false)
        })
        .catch((error) => {
          setLoader(false)
        })
    }
  }, [CountryID])

  useEffect(() => {
    if (ServiceCall && page && TicketStatus.length > 0) {
      setModuleData([]);
      setLoader(true);
      var Arr = []
      for (var i = 0; i < IncidentCode.length; i++) {
        Arr.push(IncidentCode[i].IncidentCode);
      }

      var StatusArr = []
      for (var j = 0; j < TicketStatus.length; j++) {
        if (TicketStatus[j].value === "Select All") {
          for (var n = 0; n < TicketStatusList.length; n++) {
            if (TicketStatusList[n].value !== "Select All") {
              StatusArr.push(TicketStatusList[n].value);
            }
          }
        } else {
          StatusArr.push(TicketStatus[j].value);
        }
      }

      axios
        .get("get_meraki_tickets?PageNo=" + page + "&Limit=" + limit + "&ClientCountryId=" + CountryID + "&Search=" + search + "&Status=" + StatusArr + "&IncidentCode=" + Arr + "&SiteStatus=" + SiteStatusCode + "&TicketCategory=" + TicketCategory + "&ShowAssignedTickets=" + (AssignTickets ? 1 : 0) + "&SiteId=" + Site + "&TicketId=" + FilterTicketId + "&BasestationId=" + BaseStationId + "&SiteName=" + SiteName + "&SiteMaintenanceName=" + Maintenance + "&GeneratedBy=" + GeneratedBy + "&Region=" + Region + "&CustomSp=" + CustomSp)
        .then((response) => {
          setModuleData(response.data.data.Tickets);
          setTotalTicketCount(response.data.data.TotalTicketCount);
          setTicketsModuleAccess(response.data.data.ModuleAccess);
          setNoOfRecords(response.data.NoOfRecords);
          setServiceCall(false);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [ServiceCall, page, limit, search, CountryID, IncidentCode, SiteStatusCode, TicketStatus, TicketCategory, AssignTickets, Site, BaseStationId, FilterTicketId, SiteName, Maintenance, TicketStatusList, GeneratedBy, Region, CustomSp]);

  const clearSearchHandler = () => {
    setSiteName("");
    setBaseStationId("")
    setServiceCall(true)
    setSearched(false)
    setSearchInput("")
    setSite("")
    setTicketStatus([]);
    setIncidentCode([])
    setSiteStatusCode("")
    setTicketCategory("");
    setFilterTicketId("")
    setAssignTickets(false);
    setMaintenance("")
    setGeneratedBy("");
    setRegion("")
    setCustomSp("0")
    navigate("?page=1&Search=&Status=&IncidentCode=&SiteStatus=&TicketCategory=&ShowAssignedTickets=&Maintenance=&CustomSp=0")
  }

  useEffect(() => {
    if (searched) {
      var Str = []
      for (var i = 0; i < IncidentCode.length; i++) {
        Str += IncidentCode[i].IncidentCode
        if (i !== IncidentCode.length - 1) {
          Str += ",";
        }
      }

      var Status = []
      for (var j = 0; j < TicketStatus.length; j++) {
        Status += TicketStatus[j].value
        if (j !== TicketStatus.length - 1) {
          Status += "-";
        }
      }
      navigate("?page=" + page + "&Limit=" + limit + "&Search=" + searchInput + "&Status=" + Status + "&IncidentCode=" + Str + "&SiteStatus=" + SiteStatusCode + "&TicketCategory=" + TicketCategory + "&ShowAssignedTickets=" + (AssignTickets ? 1 : 0) + "&TicketId=" + FilterTicketId + "&BasestationId=" + BaseStationId + "&SiteName=" + SiteName + "&Maintenance=" + Maintenance + "&GeneratedBy=" + GeneratedBy + "&Region=" + Region + "&CustomSp=" + CustomSp)
      setServiceCall(true);
      setSearched(false)
    }
  }, [navigate, searched, page, limit, searchInput, TicketStatus, IncidentCode, SiteStatusCode, TicketCategory, AssignTickets, BaseStationId, FilterTicketId, SiteName, Maintenance, GeneratedBy, Region, CustomSp])

  useEffect(() => {
    if (TicketSelected !== "" && CountryID > 0) {
      setLoader(true);
      axios
        .get(
          "get_email_body_meraki?ClientCountryId=" + CountryID + "&TicketId=" + TicketSelected
        )
        .then((response) => {
          setEmailData(response.data.data)
          setEmailBodyFlag(true);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [TicketSelected, CountryID])

  const HandleFaultDetails = (response) => {
    if (response && CreateFaultTicketId !== "" && CountryID > 0) {
      setLoader(true);
      axios
        .post(
          "create_fault_meraki_ticket?ClientCountryId=" + CountryID + "&TicketId=" + CreateFaultTicketId
        )
        .then((response) => {
          if (parseInt(response.status) === 200) {
            toast.success(response.data.status)
            setCreateFaultTicketId("");
            setServiceCall(true);
          } else {
            toast.error(response.data.status)
            setCreateFaultTicketId("");
            setServiceCall(true);
          }
          setLoader(false);
        })
        .catch((response) => {
          setLoader(false);
          toast.error(response.response.data.status)
          setCreateFaultTicketId("");
          setServiceCall(true);
        });
    } else {
      setCreateFaultTicketId("");
    }
  }

  const CloseFault = (response) => {
    if (response && CloseFaultTicketId !== "" && CountryID > 0) {
      setLoader(true);
      axios
        .post(
          "close_meraki_ticket?ClientCountryId=" + CountryID + "&TicketId=" + CloseFaultTicketId
        )
        .then((response) => {
          if (parseInt(response.status) === 200) {
            toast.success(response.data.status)
            setCloseFaultTicketId("");
            setServiceCall(true);
          } else {
            toast.error(response.data.status)
            setCloseFaultTicketId("");
            setServiceCall(true);
          }
          setLoader(false);
        })
        .catch((response) => {
          setLoader(false);
          toast.error(response.response.data.status)
          setCloseFaultTicketId("");
          setServiceCall(true);
        });
    } else {
      setCloseFaultTicketId("");
    }
  }

  const handleExportTicket = () => {
    setLoader(true)
    const TimeZone = localStorage.getItem("TimeZone") ? JSON.parse(localStorage.getItem("TimeZone")) : Intl.DateTimeFormat().resolvedOptions().timeZone
    const TimeZoneParm = (typeof TimeZone === 'string') ? TimeZone : TimeZone.value

    var Arr = []
    for (var i = 0; i < IncidentCode.length; i++) {
      Arr.push(IncidentCode[i].IncidentCode);
    }

    var StatusArr = []
    for (var j = 0; j < TicketStatus.length; j++) {
      if (TicketStatus[j].value === "Select All") {
        for (var n = 0; n < TicketStatusList.length; n++) {
          if (TicketStatusList[n].value !== "Select All") {
            StatusArr.push(TicketStatusList[n].value);
          }
        }
      } else {
        StatusArr.push(TicketStatus[j].value);
      }
    }

    axios.get("export_meraki_tickets?ClientCountryId=" + CountryID + "&Search=" + search + "&Status=" + StatusArr + "&IncidentCode=" + Arr + "&SiteStatus=" + SiteStatusCode + "&TicketCategory=" + TicketCategory + "&ShowAssignedTickets=" + (AssignTickets ? 1 : 0) + "&Timezone=" + TimeZoneParm + "&SiteMaintenanceName=" + Maintenance + "&GeneratedBy=" + GeneratedBy + "&Region=" + Region + "&CustomSp=" + CustomSp)
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false)
        setSuccess(response.data.status.message)
      })
      .catch((error) => {
        setLoader(false)
      })
  }

  const HandleUndiferred = (response) => {
    if (response && UndiferredTicketId !== "" && CountryID > 0) {
      setLoader(true);
      const Payload = {
        "ClientCountryId": CountryID,
        "TicketId": UndiferredTicketId
      }
      axios
        .post(
          "undeferred_meraki_ticket", Payload
        )
        .then((response) => {
          if (parseInt(response.data.status.code) === 200) {
            toast.success(response.data.status.message)
            setServiceCall(true);
          } else {
            toast.error(response.data.status.message)
          }
        })
        .catch((response) => {
          toast.error(response.response.data.status)
          setServiceCall(true);
        });
      setLoader(false);
      setUndiferredTicketId("");

    } else {
      setUndiferredTicketId("");
    }
  }
  const handlePostEvent = (response) => {

    if (response && PostEventTicketId !== "" && CountryID > 0) {
      setLoader(true);
      const Payload = {
        "ClientCountryId": CountryID,
        "TicketId": PostEventTicketId
      }
      axios
        .post(
          "post_meraki_event_to_clientcallback",
          Payload
        )
        .then((response) => {
          if (parseInt(response.status) === 200) {
            toast.success(response.data.status.message)
            setPostEventTicketId("");
            setServiceCall(true);
          } else {
            toast.error(response.data.status)
            setPostEventTicketId("");
            setServiceCall(true);
          }
          setLoader(false);
        })
        .catch((response) => {
          setLoader(false);
          toast.error(response.response.data.status)
          setPostEventTicketId("");
          setServiceCall(true);
        });

    } else {
      setPostEventTicketId("");
    }

  }


  return (
    <>
      <ToastContainer theme="colored" />
      {loader ? <Loader /> : null}

      {
        JSON.stringify(TicketDescriptionData) !== "{}" ?
          <TicketDescription TicketDescriptionData={TicketDescriptionData} setTicketDescriptionData={setTicketDescriptionData} CountryID={CountryID} setServiceCall={setServiceCall} TicketType={"Meraki"} />
          : null
      }

      {PostEventTicketId !== "" ? (
        <Alert
          message="Are you sure, you want to post event to client callback?"
          AlertResponse={handlePostEvent}
        />
      ) :
        null
      }

      {
        JSON.stringify(CustomSpData) !== "{}" ? <AddCustomSp Flag={"Meraki"} setLoader={setLoader} setCustomSpData={setCustomSpData} CustomSpData={CustomSpData} CustomSpList={CustomSpList} CountryID={CountryID} setServiceCall={setServiceCall} /> : null
      }

      {
        JSON.stringify(TicketLogId) !== "{}" ? <Log TicketLogId={TicketLogId} setTicketLogId={setTicketLogId} CountryID={CountryID} setServiceCall={setServiceCall} /> : null
      }

      {ManualTicketFlag ? <ManualMerakiTicketGenaration setServiceCall={setServiceCall} CountryID={CountryID} setManualTicketFlag={setManualTicketFlag} /> : null}

      {JSON.stringify(DeferTicketDate) !== "{}" ? <MerakiAddDiferDetails DeferTicketDate={DeferTicketDate} setDeferTicketDate={setDeferTicketDate} CountryID={CountryID} setServiceCall={setServiceCall}
      /> : null}

      {DiferDetailsId !== "" ? <MerakiDiferDetails DiferDetailsId={DiferDetailsId} setDiferDetailsId={setDiferDetailsId} CountryID={CountryID} /> : null}

      {
        UndiferredTicketId !== "" ?
          <Alert
            message="Are you sure, you want to undefer ticket?"
            AlertResponse={HandleUndiferred}
          /> : null
      }

      {FaultDetailsId !== "" ? <MerakiFaultDetails CountryID={CountryID} FaultDetailsId={FaultDetailsId} setFaultDetailsId={setFaultDetailsId} /> : ""}
      {CreateFaultTicketId !== "" ? (
        <Alert
          message="Are you sure, you want to create fault?"
          AlertResponse={HandleFaultDetails}
        />
      ) :
        null
      }

      {UpdateFaultId !== "" ?
        <MerakiUpdateFault ClickUpdateFaultId={ClickUpdateFaultId} setClickUpdateFaultId={setClickUpdateFaultId} CountryID={CountryID} UpdateFaultId={UpdateFaultId} setUpdateFaultId={setUpdateFaultId} />
        : null
      }

      {
        CloseFaultTicketId !== "" ?
          <Alert
            message="Are you sure, you want to close ticket? (If fault is created it will closed automatically on close ticket)"
            AlertResponse={CloseFault}
          /> : null
      }
      {EmailBodyFlag ? <MerakiEmailBodyView TicketsModuleAccess={TicketsModuleAccess} TicketSelected={TicketSelected} setTicketSelected={setTicketSelected} CountryID={CountryID} EmailData={EmailData} setEmailBodyFlag={setEmailBodyFlag} /> : null}

      <div className="row">
        <div className="col-md-6">
          <h6 className="mb-2">Meraki Tickets</h6>
        </div>
        <div className="col-sm-4">
          <input
            type="text"
            className="form-control"
            id="search"
            name="search"
            title="Search Ticket Id, Basestation Id, Site Host, Site Name, Fresh Service Ticket Id, Fault Reference Number."
            placeholder="Search Ticket Id ,  Basestation Id ,  Site Host,  Site Name ,  Fresh Service Ticket Id ,  Fault Reference Number "
            value={searchInput}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                setSearched(true)
              }
            }}
            onChange={(e) => { setSearchInput(e.target.value); setSite(""); setFilterTicketId(""); setBaseStationId(""); setSiteName("") }}
          />
        </div>
        <div className="col-md-2 ">
          <select
            className="form-select"
            onChange={(e) => {
              setGeneratedBy(e.target.value)
              setSearched(true)
            }}
            value={GeneratedBy}
          >
            <option value="">Select Generated By</option>
            {
              GeneratedByList.map((item, index) => (
                <option key={index} value={item}> {item} </option>
              ))
            }
          </select>
        </div>
      </div>
      <hr />

      <div className="row">

        <div className="col-sm-2">
          <select
            className="form-select"
            onChange={(e) => {
              setTicketCategory(e.target.value)
              setSearched(true)
              setSite("");
              setFilterTicketId("");
              setBaseStationId("");
              setSiteName("")
            }}
            value={TicketCategory}
          >
            <option value="">Select Ticket Category</option>
            {
              TicketCategoryList.map((item, index) => (
                <option key={index} value={item.key}> {item.val} </option>
              ))
            }
          </select>
        </div>

        <div className="col-sm-2">
          <ReactSelect
            options={TicketStatusList}
            isMulti
            placeholder='Select Ticket Status'
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            // defaultMenuIsOpen
            components={{
              Option
            }}
            onChange={(selected) => {
              if (selected !== null) {
                if (selected.length === 0) {
                  const selected_arr = TicketStatusList.filter(item => item.value === 'Select All');
                  setTicketStatus(selected_arr);
                } else {
                  const lastSelectedValue = selected[selected.length - 1].value;
                  if (lastSelectedValue === 'Select All') {
                    const selected_arr2 = selected.filter(item => item.value === 'Select All');
                    setTicketStatus(selected_arr2);
                  } else {
                    const filteredSelected = selected.filter(item => item.value !== 'Select All');
                    setTicketStatus(filteredSelected);
                  }
                }
              }
              setSite("");
              setFilterTicketId("");
              setSiteName("")
              setSearched(true)
            }}
            allowSelectAll={true}
            value={TicketStatus ? TicketStatus : null}
          />
        </div>
        <div className="col-sm-2">
          <ReactSelect
            options={IncidentCodeList}
            isMulti
            closeMenuOnSelect={false}
            placeholder='Select Incident Code'
            hideSelectedOptions={false}
            // defaultMenuIsOpen
            components={{
              Option
            }}
            onChange={(selected) => {
              setIncidentCode(selected);
              setSearched(true)
              setSite("");
              setFilterTicketId("");
              setBaseStationId("");
              setSiteName("")
            }}
            allowSelectAll={true}
            value={IncidentCode ? IncidentCode : null}
          />
        </div>
        <div className="col-sm-6 row">
          <div className="col-sm-3">
            <select
              className="form-select"
              onChange={(e) => {
                setSiteStatusCode(e.target.value)
                setSearched(true)
                setSite("");
                setFilterTicketId("");
                setBaseStationId("");
                setSiteName("")
              }}
              value={SiteStatusCode}
            >
              <option value="">Select Site Status</option>
              {
                SiteStatusCodeList.map((item, index) => (
                  <option key={index} value={item.SiteStatusCode}> {item.SiteStatusValue} </option>
                ))
              }
            </select>
          </div>

          <div className="col-sm-3">
            <select
              className="form-select"
              onChange={(e) => {
                setMaintenance(e.target.value)
                setSearched(true)
              }}
              value={Maintenance}
            >
              <option value="">Select Maintenance</option>
              {
                MaintenanceList.map((item, index) => (
                  <option key={index} value={item}> {item} </option>
                ))
              }
            </select>
          </div>
          <div className="col-sm-3">
            <select
              className="form-select"
              onChange={(e) => {
                setRegion(e.target.value)
                setSearched(true)
              }}
              value={Region}
            >
              <option value=""> Select Region</option>
              {
                RegionList.map((item, index) => (
                  <option key={index} value={item}> {item} </option>
                ))
              }
            </select>
          </div>
          <div className="col-sm-3">
            <select
              className="form-select"
              onChange={(e) => {
                setCustomSp(e.target.value);
                setSearched(true);
              }}
              value={CustomSp}
            >
              <option value="0">Select Service Provider</option>
              {CustomSpList.map((item) => {
                return (
                  <option key={item.Id} value={item.Id}>
                    {item.CustomSp}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="row d-flex align-items-center mb-3">
        <div className="col-sm-2">
          Count : <b>{NoOfRecords} / {TotalTicketCount}</b>
        </div>
        <div className="col-sm-10 text-right">
          &nbsp;&nbsp;
          {(searched || TicketStatus || IncidentCode || SiteStatusCode || TicketCategory || searchInput || Site || Region) ?
            <button
              type="button"
              className="btn btn-primary"
              title="Reset Filters"
              onClick={clearSearchHandler}
            >
              <i className="bx bx-eraser search-icon"></i>
            </button>
            : null}
          &nbsp;&nbsp;
          {
            TicketsModuleAccess === 'write' ?
              <button
                type="button"
                className="btn btn-primary"
                title="Export"
                onClick={handleExportTicket}
                style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px' }}
              >
                Export &nbsp;
              </button> : null
          }

          {
            UserGroup.Id < 4 && TicketsModuleAccess === 'write' ?
              <>
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-primary mx-2"
                  title="Create Manual Ticket"
                  onClick={() => {
                    setManualTicketFlag(true)
                  }} style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px', float: 'right' }}
                >
                  Create Manual Ticket &nbsp;
                </button>
              </>
              : ""
          }

        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <div style={{ maxHeight: '64vh' }} className=" horizontal-scroll">
                <table className="table mb-0 table-striped ticket-table table-hover">
                  <thead style={{ zIndex: "1" }}>
                    <tr>
                      <th style={{ width: "70px" }}>#</th>
                      <th >Action</th>

                      {UserGroup.Id < 5 && TicketsModuleAccess === 'write' ?
                        <th>Add Service Provider</th> : null
                      }
                      <th>Service Provider</th>
                      <th>Client Callback</th>
                      {/* <th>Fault Id</th> */}
                      <th>Created From</th>
                      <th>Ticket Category</th>
                      <th>Dynamic SLA</th>
                      <th>Ticket Id</th>
                      <th>Fresh Service Ticket Id</th>
                      <th>Basestation Id</th>
                      <th>Site Lessor Id</th>
                      <th>Incident Code</th>
                      <th>Ticket Status</th>
                      <th>Site Name</th>
                      <th>Site Status</th>
                      <th>Site Host</th>
                      <th>Maintenance</th>
                      <th>Region</th>
                      <th>TicketLastUpdates</th>
                      <th>Occurred Time</th>
                      <th>Generated Time</th>
                      <th>Incident Dispatched Time</th>
                      <th>Deferred Time</th>
                      <th>Resolved Time</th>
                      <th>Closed Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ModuleData.length > 0 ?
                      ModuleData.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{((page - 1) * limit) + index + 1}</td>
                            <td className="action-buttons">

                              {TicketsModuleAccess === 'write' ?
                                <>
                                  < i style={{ color: (item.IsForwarded === 1) ? "#17A00E" : "#0d6efd" }} className="bx bx-mail-send" title="View Mail" onClick={() => setTicketSelected(item.TicketId)}></i>
                                  <span> &nbsp; | &nbsp;</span>
                                </>
                                : null
                              }

                              {item.TicketStatus !== TICKET_STATUS_CLOSED && UserGroup.Id < 4 && TicketsModuleAccess === 'write' ? <>
                                <i
                                  className="bx bx-x-circle text-danger"
                                  title="Close Ticket" style={{ color: "red" }}
                                  onClick={() => {
                                    setCloseFaultTicketId(item.TicketId)
                                  }}
                                ></i>
                                <span> &nbsp; | &nbsp;</span>
                              </> : null}

                              {/* Defferred section  */}
                              {MERAKI_DEFERRED_CONDITION.includes(item.TicketStatus) && TicketsModuleAccess === 'write' ?
                                <>
                                  <span onClick={() => {
                                    setDeferTicketDate(item);
                                  }} title="Defer" >
                                    <svg className="svg-icon difer-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M512 39.384615C252.061538 39.384615 39.384615 252.061538 39.384615 512s212.676923 472.615385 472.615385 472.615385 472.615385-212.676923 472.615385-472.615385S771.938462 39.384615 512 39.384615z m0 827.076923c-194.953846 0-354.461538-159.507692-354.461538-354.461538S317.046154 157.538462 512 157.538462s354.461538 159.507692 354.461538 354.461538-159.507692 354.461538-354.461538 354.461538z m66.953846-350.523076c-5.907692-5.907692-7.876923-13.784615-7.876923-21.661539v-189.046154c0-15.753846-13.784615-29.538462-29.538461-29.538461h-59.076924c-15.753846 0-29.538462 13.784615-29.538461 29.538461v238.276923c0 7.876923 3.938462 15.753846 7.876923 21.661539l145.723077 145.723077c11.815385 11.815385 29.538462 11.815385 41.353846 0L689.230769 669.538462c11.815385-11.815385 11.815385-29.538462 0-41.353847l-110.276923-112.246153z" /></svg>
                                  </span>
                                  <span> &nbsp; | &nbsp;</span>
                                </>
                                : ""}


                              <i className="bx bx-info-circle box-icon-style" type='solid' title="Deferred Details" onClick={() => {
                                setDiferDetailsId(item.TicketId)
                              }}></i>
                              <span> &nbsp; | &nbsp;</span>

                              {MERAKI_UNDEFERRED_CONDITION.includes(item.TicketStatus) && TicketsModuleAccess === 'write' ?
                                <>
                                  <i className="bx bx-revision box-icon-style" type='solid' title="Undefer" onClick={() => {
                                    setUndiferredTicketId(item.TicketId)
                                  }} ></i>
                                  <span> &nbsp; | &nbsp;</span>
                                </>
                                : null
                              }

                              {
                                OPENGROUPTICKETSTATUSARR.includes(item.TicketStatus) && UserGroup.Id < 4 && TicketsModuleAccess === 'write' ?
                                  <>
                                    <i className="bx bx-edit-alt box-icon-style" title="Add Ticket Update" onClick={() => {
                                      setTicketDescriptionData(item)
                                    }} ></i>
                                    <span> &nbsp; | &nbsp;</span>
                                  </> : null
                              }

                              <div style={{ position: "relative", display: "inline-block" }} title="Ticket Logs" onClick={() => {
                                setTicketLogId(item)
                              }}>
                                <i className="bx bx-notepad cursor-pointer box-icon-style"></i>
                                {item.UnseenCount > 0 && (
                                  <span className="ticket-logs-badge">
                                    {item.UnseenCount}
                                  </span>
                                )}
                              </div>

                            </td>

                            {/* CUSTOM SP */}
                            {
                              UserGroup.Id < 5 && TicketsModuleAccess === 'write' ?
                                <td style={{ textAlign: "center", verticalAlign: "center" }}>
                                  {
                                    OPENGROUPTICKETSTATUSARR.includes(item.TicketStatus) ?
                                      <i className="bx bx-plus box-icon-style" onClick={() => {
                                        setCustomSpData(item)
                                      }}></i>
                                      : "-"
                                  }
                                </td>
                                : null
                            }
                            <td style={{ textAlign: "center", verticalAlign: "center" }}>{item.CustomSp ? item.CustomSp : "-"}</td>

                            <td style={{ textAlign: "center" }}>
                              {
                                OPENGROUPTICKETSTATUSARR?.includes(item.TicketStatus) ?
                                  parseInt(item.PostEventFlag) === 0 && parseInt(item.CustomSpId) !== 0 && CountryEnabledCustomSp?.includes(parseInt(item.CustomSpId)) && UserGroup.Id < 5 && TicketsModuleAccess === 'write' ? (
                                    <button
                                      title="Post Event"
                                      className="btn btn-primary"
                                      style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 10px' }}
                                      onClick={() => setPostEventTicketId(item.TicketId)}
                                    >
                                      Post Ticket
                                    </button>
                                  ) : parseInt(item.PostEventFlag) === 1 ? (
                                    <span style={{ color: 'green' }}><i className="bx bx-check text-success cursor-pointer" title="Event posted."></i></span>
                                  ) : (
                                    "-"
                                  )
                                  : "-"
                              }
                            </td>

                            {/* <td className="action-buttons" style={{ textAlign: "center" }}>
                              {
                                parseInt(item.FaultStatus) === 0 ?
                                  MERAKI_INCIDENT_DISPATCHED_CONDITION.includes(item.TicketStatus) ?
                                    <button title="Create Fault" className="btn btn-primary" style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 10px' }}
                                      onClick={() => {
                                        setCreateFaultTicketId(item.TicketId)
                                      }}
                                    >Create Fault</button> : "-"
                                  :
                                  <div className="d-flex align-items-center text-success" >
                                    {item.FaultId}
                                    <i className="bx bx-show box-icon-style" title="Fault Details" onClick={() => {
                                      setFaultDetailsId(item.FaultId)
                                    }}></i>
                                    {
                                      item.TicketStatus !== TICKET_STATUS_CLOSED ?
                                        <i
                                          className=" bx bx-note box-icon-style"
                                          title="Update Fault"
                                          onClick={() => {
                                            setUpdateFaultId(item.TicketId)
                                            setClickUpdateFaultId(item.FaultId)
                                          }}
                                        ></i> : ""
                                    }
                                  </div>
                              }
                            </td> */}
                            <td>
                              {item.CreatedFrom}
                            </td>
                            <td>{item.TicketCategory}</td>
                            <td>{item.DynamicSla}</td>
                            <td>{item.TicketId}</td>
                            <td>{item.FreshServiceTicketId}</td>
                            <td>{item.BasestationId}</td>
                            <td>{item.BasestationLessorId}</td>
                            <td>{item.IncidentCode}</td>
                            <td>{item.TicketStatus}</td>
                            <td>{item.SiteName}</td>
                            <td>{item.SiteStatus}</td>
                            <td>{item.SiteHost}</td>
                            <td >{item.SiteMaintenanceName}</td>
                            <td >{item.Region}</td>
                            <td style={{ textAlign: "left", width: "50px" }}>

                              <div style={{ minWidth: "400px" }} >
                                <span style={{ whiteSpace: "pre-wrap" }}> {item.TicketLastUpdates ? item.TicketLastUpdates : "-"}</span><br />
                              </div>

                            </td>
                            <td>{(item.TicketOccuredTime > 0) ? <TimeZone timestamp={item.TicketOccuredTime} /> : null}</td>
                            <td>{(item.CreatedTimestamp > 0) ? <TimeZone timestamp={item.CreatedTimestamp} /> : null}</td>
                            <td>{(item.IncidentDispatchedTimestamp > 0) ? <TimeZone timestamp={item.IncidentDispatchedTimestamp} /> : null}</td>
                            <td>{(item.DiferredTimestamp > 0) ? <TimeZone timestamp={item.DiferredTimestamp} /> : null}</td>
                            <td>{(item.ResolvedTimestamp > 0) ? <TimeZone timestamp={item.ResolvedTimestamp} /> : null}</td>
                            <td>{(item.ClosedTimestamp > 0) ? <TimeZone timestamp={item.ClosedTimestamp} /> : null}</td>
                          </tr>
                        );
                      }) :
                      <tr>
                        <td
                          colSpan={23}
                          style={{ textAlign: "center" }}>
                          <b>No Record Found</b>
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {
            NoOfRecords > 0 ?
              <Pagination
                records={ModuleData.length}
                link={"meraki-tickets-list/" + CountryID}
                SelectedPage={page}
                search={"&Search=" + searchInput + "&Status=" + TicketStatus + "&IncidentCode=" + IncidentCode + "&SiteStatus=" + SiteStatusCode + "&TicketCategory=" + TicketCategory + "&Maintenance=" + Maintenance + "&GeneratedBy=" + GeneratedBy + "&Region=" + Region}
                NoOfRecords={NoOfRecords}
                limit={limit}
              /> : null
          }
        </div>
      </div >
    </>
  );
}

export default MerakiTicketsList;