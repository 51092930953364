import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../axios";
import Loader from "../../Components/Loader";
import Switch from "react-switch";
import Alert from "../../Components/Alert";
import DefaultLogo from "../../Images/foxilogic-disable.png"
import { CUSTOM_CLIENT_SELECT } from '../../Custom';

const ViewSiteStatusConfig = () => {
    var UserGroup = JSON.parse(localStorage.getItem("UserGroup"));
    var ClientId = JSON.parse(localStorage.getItem("ClientId"));

    const [loader, setLoader] = useState(false);
    const [Clients, setClients] = useState([]);
    const [ClientID, setClientID] = useState(ClientId || "-1");
    const [success, setSuccess] = useState("");
    const [SiteStatusList, setSiteStatusList] = useState([])
    const [ApplyAllAlert, setApplyAllAlert] = useState("");
    const [ModuleAccess, setModuleAccess] = useState("");

    const getClientValue = () => {
        if (ClientID) {
            for (var i = 0; i < Clients.length; i++) {
                if (Clients[i].Id === parseInt(ClientID)) {
                    return [{ value: Clients[i].Id, label: Clients[i].Name, image: Clients[i].image }];
                }
            }
        }
    }

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess("");
        }
        return () => { };
    }, [success]);

    const OnchangeClient = (selected) => {
        setClientID(selected.Id)
    }

    useEffect(() => {
        if (UserGroup.Id === 1 || UserGroup.Id === 2) {
            setLoader(true);
            axios
                .get("clients")
                .then((response) => {
                    var TempData = response.data.data.data;
                    var arr = [];
                    for (var i = 0; i < TempData.length; i++) {
                        if (i === 0 && (UserGroup.Id === 1 || UserGroup.Id === 2)) {
                            var default_obj = {};
                            default_obj["Id"] = -1;
                            default_obj["Name"] = "Default Setting";
                            default_obj["label"] = "Default Setting";
                            default_obj["value"] = -1;
                            default_obj["image"] = DefaultLogo;
                            arr.push(default_obj)
                        }

                        var obj = TempData[i]
                        obj["label"] = TempData[i].Name;
                        obj["value"] = TempData[i].Id;
                        obj["image"] = TempData[i].ClientLogo ? TempData[i].ClientLogo : DefaultLogo;
                        arr.push(obj)
                    }
                    setClients(arr)

                    // setClients(response.data.data);
                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                });
        }
    }, [UserGroup.Id]);

    useEffect(() => {
        if (ClientID) {
            setLoader(true)
            axios.get("/list_site_status?ClientId=" + ClientID)
                .then((response) => {
                    setSiteStatusList(response.data.data.data)
                    setModuleAccess(response.data.data.ModuleAccess)
                    setLoader(false)
                })
                .catch((error) => {
                    setLoader(false)
                })
        }
    }, [ClientID])

    const handleConfig = (index, GetGenerateTicket) => {
        var SiteStatusTemp = JSON.parse(JSON.stringify(SiteStatusList));
        SiteStatusTemp[index].GenerateTicket = (GetGenerateTicket === 0) ? 1 : 0;
        setSiteStatusList(SiteStatusTemp);
    }

    const submitSiteStatus = (type) => {
        setLoader(true);
        const param = {
            ClientId: ClientID,
            SubmitType: type,
            SiteStatusConfig: SiteStatusList
        }
        axios.put("update_site_status", param)
            .then((response) => {
                setLoader(false)
                setSuccess(response.data.status.message)
                setSiteStatusList(response.data.data)
            })
            .catch((error) => {
                setLoader(false)
            })
    }

    const AlertResponse = (Result) => {
        if (Result) {
            submitSiteStatus("SaveToAll")
        }
        setApplyAllAlert('')
    }

    return (
        <div>
            {ApplyAllAlert ? <Alert message={ApplyAllAlert} AlertResponse={AlertResponse} /> : null}
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            <div className="row">
                <div className="col-md-8">
                    <h6 className="mb-0">Site Status Config List</h6>
                </div>
            </div>
            <hr />

            <div className="card">
                <div className="card-body horizontal-scroll">
                    <div className="row">
                        <div className="col-md-6">
                            {UserGroup.Id === 1 || UserGroup.Id === 2 ? (
                                <>
                                    {CUSTOM_CLIENT_SELECT({ ClientListOptions: Clients, Value: getClientValue(), OnchangeClient: OnchangeClient })}
                                </>
                            ) : null}
                        </div>

                        {
                            ModuleAccess === "write" ?
                                <div className="col-md-6">
                                    <button
                                        type="submit"
                                        className="btn btn-primary px-5"
                                        style={{ marginTop: "20px", float: "right", marginLeft: "10px" }}
                                        onClick={() => submitSiteStatus("Save")}
                                    >
                                        Save
                                    </button>
                                    &nbsp;
                                    {((UserGroup.Id === 1 || UserGroup.Id === 2) && ClientID === '-1') ?
                                        <button
                                            type="submit"
                                            className="btn btn-primary px-5"
                                            style={{ marginTop: "20px", float: "right" }}
                                            onClick={() => setApplyAllAlert('Are you sure you want to apply all?')}
                                        >
                                            Apply to all
                                        </button>
                                        : null}
                                </div>
                                : null
                        }

                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <table
                                className="table mb-0 table-striped text-center"
                                style={{ verticalAlign: "middle" }}
                            >
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th style={{ textAlign: "left" }}>Name</th>
                                        <th style={{ textAlign: "left" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {SiteStatusList.length > 0 ?
                                        SiteStatusList.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td style={{ textAlign: "left" }}> {item.SiteStatusValue} </td>
                                                    <td style={{ textAlign: "left" }}>
                                                        <Switch
                                                            className="login-switch"
                                                            onColor={"#136dfa"}
                                                            height={25}
                                                            checkedIcon={false}
                                                            uncheckedIcon={false}
                                                            checked={parseInt(item.GenerateTicket) === 0 ? false : true}
                                                            onChange={() => handleConfig(index, item.GenerateTicket)}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr>
                                            <td
                                                colSpan={3}
                                                style={{ textAlign: "center" }}>
                                                <b>No Record Found</b>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {
                        ModuleAccess === "write" ?
                            <div className="row">
                                <div className="col-md-12">
                                    <button
                                        type="submit"
                                        className="btn btn-primary px-5"
                                        style={{ marginTop: "20px", float: "right", marginLeft: "10px" }}
                                        onClick={() => submitSiteStatus("Save")}
                                    >
                                        Save
                                    </button>
                                    {((UserGroup.Id === 1 || UserGroup.Id === 2) && ClientID === '-1') ?
                                        <button
                                            type="submit"
                                            className="btn btn-primary px-5"
                                            style={{ marginTop: "20px", float: "right" }}
                                            onClick={() => submitSiteStatus("SaveToAll")}
                                        >
                                            Apply to all
                                        </button>
                                        : null}
                                </div>
                            </div>
                            : null
                    }
                </div>
            </div>

        </div>
    )
}

export default ViewSiteStatusConfig
