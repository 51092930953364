import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link } from 'react-router-dom';
import Loader from "../../Components/Loader";
import axios from "../../axios";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "../../Components/Alert";
import ReactSwitch from "react-switch";

const List = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [ModuleData, setModuleData] = useState([])
  const [ServiceCall, setServiceCall] = useState(true)
  const [DeleteUserId, setDeleteUserId] = useState("");
  const [ModuleAccess, setModuleAccess] = useState("");

  useEffect(() => {
    if (success) {
      toast.success(success);
      setSuccess("");
    }
    return () => { };
  }, [success]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError("");
    }
    return () => { };
  }, [error]);

  useEffect(() => {
    if (location.state) {
      setSuccess(location.state);
      navigate("/impacted_devices_notification_config", {
        state: null,
      });
    }
  }, [location, navigate]);

  useEffect(() => {
    if (ServiceCall) {
      setLoader(true)
      axios.get("get_all_impacted_device_config")
        .then((response) => {
          setModuleData(response.data.data.data)
          setModuleAccess(response.data.data.ModuleAccess)
          setLoader(false)
          setServiceCall(false)
        })
        .catch((error) => {
          setLoader(false)
        })
    }
  }, [ServiceCall])

  const AlertResponse = (response) => {
    if (response === true) {
      setLoader(true);
      axios
        .get("delete_impacted_device_config?DeleteId=" + DeleteUserId)
        .then((response) => {
          setServiceCall(true);
          setDeleteUserId("");
          setSuccess("Record deleted successfully");
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    } else {
      setDeleteUserId("");
    }
  };

  const ChangeActiveStatus = (Id, Active) => {
    const Payload = {
      "Id": Id,
      "Active": parseInt(Active) === 0 ? 1 : 0
    }
    axios.post("change_active_status_impacted_device_config", Payload)
      .then((response) => {
        setServiceCall(true)

      })
      .catch((error) => {
      })
  }



  return (
    <div>
      <ToastContainer theme="colored" />
      {loader ? <Loader /> : null}

      {DeleteUserId ?
        <Alert
          message="Are you sure, you want to delete this record?"
          AlertResponse={AlertResponse}
        />
        :
        ""
      }
      <div className="row">
        <div className="col-md-8">
          <h6 className="mb-0 ">Impacted Devices Notification Configuration</h6>
        </div>

        {ModuleAccess === "write" ?
          <div className="col-md-4 text-right">
            <Link to="/add_impacted_devices_notification_config" className="btn btn-primary">
              <i className="bx bx-plus me-1 font-22"></i>Add
            </Link>
          </div>
          : null}

      </div>
      <hr />

      <div className="row">
        <div className="col-sm-3">

        </div>
      </div>

      <div className="card">
        <div className="card-body horizontal-scroll">
          <div className="row g-3" style={{ marginBottom: "12px" }}>
          </div>

          <table className="table mb-0 table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Channel Partner Name</th>
                <th>No. of Impacted Devices</th>
                <th>Email</th>
                <th>Active</th>
                {
                  ModuleAccess === "write" ? <th>Action</th> : null
                }

              </tr>
            </thead>
            <tbody>
              {ModuleData.length > 0 ?
                ModuleData.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.ChannelPartnerName}</td>
                      <td>{item.NoOfImpactedDevice}</td>
                      <td>{item.Email}</td>
                      <td>
                        <ReactSwitch
                          className="login-switch"
                          onColor={"#136dfa"}
                          height={25}
                          checkedIcon={false}
                          uncheckedIcon={false}
                          checked={parseInt(item.Active) === 1 ? true : false}
                          onChange={() => ChangeActiveStatus(item.Id, item.Active)}
                        />
                      </td>
                      {ModuleAccess === "write" ?
                        <td className="action-buttons">
                          <i
                            className="bx bx-trash text-danger"
                            title="Delete"
                            onClick={() => setDeleteUserId(item.Id)}
                          ></i>

                          <Link to={"/add_impacted_devices_notification_config/" + item.Id}>
                            <i className="bx bx-pencil" title="Edit"></i>
                          </Link>
                        </td> : null}
                    </tr>
                  );
                }) :
                <tr>
                  <td
                    colSpan={6}
                    style={{ textAlign: "center" }}>
                    <b>No Record Found</b>
                  </td>
                </tr>}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default List