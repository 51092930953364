import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import ModuleComponent from "./ModuleComponent";
import Logo from "../Images/foxilogic.png";
import { timezone } from "../features/TimeZoneSlice";
import TimezoneSelect, { allTimezones } from 'react-timezone-select'
import { useDispatch } from "react-redux";
import ChangePassword from "../Authorization/ChangePassword";


const Template = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const UserGroup = JSON.parse(localStorage.getItem("UserGroup"));
  const ClientId = JSON.parse(localStorage.getItem("ClientId"));

  const TempModules = localStorage.getItem("Modules")
    ? JSON.parse(localStorage.getItem("Modules"))
    : [];
  const [MiniBar, setMiniBar] = useState(window.innerWidth > 1024 ? true : false);
  const [MiniBarStyle, setMiniBarStyle] = useState({ width: "70px" });
  const [Modules, setModules] = useState(TempModules);
  const [selectedTimezone, setSelectedTimezone] = useState(localStorage.getItem("TimeZone") ? JSON.parse(localStorage.getItem("TimeZone")) : Intl.DateTimeFormat().resolvedOptions().timeZone)
  const [error, setError] = useState("");
  const [ChangePasswordFlag, setChangePasswordFlag] = useState(false);


  const recursiveModules = useCallback((Module, ParentModuleIds) => {
    if (location.pathname === "/" + Module.ModuleLink && location.pathname !== "/") {
      ParentModuleIds = Module.ParentModuleIds;
    } else {
      if (Module.SubModules && Module.SubModules.length > 0) {
        for (let i = 0; i < Module.SubModules.length; i++) {
          ParentModuleIds = recursiveModules(Module.SubModules[i], ParentModuleIds);
        }
      }
    }
    return ParentModuleIds;
  }, [location.pathname])

  const addClassToParentModules = useCallback((ModulesArr, ParentModuleIds) => {
    for (let Module of ModulesArr) {
      if (ParentModuleIds.includes(Module.ModuleID)) {
        Module.ActiveClassName = "active";
      } else {
        Module.ActiveClassName = "";
      }
      if (Module.SubModules && Module.SubModules.length > 0) {
        // Recursively call and update the SubModules array
        Module.SubModules = addClassToParentModules(Module.SubModules, ParentModuleIds);
      }
    }
    return ModulesArr; // Return the updated array
  }, [])

  useEffect(() => {
    if (location.pathname) {
      let ParentModuleIds = [];
      for (let index = 0; index < Modules.length; index++) {
        ParentModuleIds = recursiveModules(Modules[index], ParentModuleIds);
      }

      var TempArr = Modules
      if (ParentModuleIds) {
        TempArr = addClassToParentModules(TempArr, ParentModuleIds);
      }
      setModules(TempArr);
    }
  }, [location.pathname, Modules, recursiveModules, addClassToParentModules]);


  useEffect(() => {
    if (location.state) {
      setError(location.state);
      location.state = null;
    }
  }, [location]);

  // useEffect(() => {
  //   if (error) {
  //     toast.(error);
  //     // setError("");
  //   }
  //   return () => { };
  // }, [error]);

  const handleChange = (e) => {
    setSelectedTimezone(e);
  }

  useEffect(() => {
    if (selectedTimezone) {
      localStorage.setItem("TimeZone", JSON.stringify(selectedTimezone))
      dispatch(timezone({ timezone: JSON.stringify(selectedTimezone) }));
    }
  }, [selectedTimezone, dispatch])


  return (
    <>

      {
        ChangePasswordFlag ?
          <ChangePassword setChangePasswordFlag={setChangePasswordFlag} />
          : null
      }
      <div className={MiniBarStyle.width && MiniBarStyle.width === "70px" ? "wrapper toggled" : "wrapper toggled sidebar-hovered"}>
        <div
          className="sidebar-wrapper"
          style={MiniBar ? MiniBarStyle : {}}
          onMouseEnter={() => {
            if (MiniBar) {
              setMiniBarStyle({});
            }
          }}
          onMouseLeave={() => {
            if (MiniBar) {
              setMiniBarStyle({ width: "70px" });
            }
          }}
        >
          <div className="sidebar-header">
            <div>
              <img src={Logo} className="logo-icon" alt="logo icon" />
            </div>
            <div>
              <h4 className="logo-text">FoxiLogic</h4>
            </div>
            <div className="toggle-icon ms-auto" onClick={() => setMiniBar(!MiniBar)}><i className="bx bx-arrow-to-left"></i></div>
          </div>


          <ul className="metismenu" id="menu">
            {/* {
              UserGroup.Id === 1 || UserGroup.Id === 2 || UserGroup.Id === 3 || UserGroup.Id === 4 ? (
                <li>
                  <Link
                    to="/home"
                    className={location.pathname === "/home" ? " mm-active" : ""}
                  >
                    <div className="parent-icon">
                      <i className="bx bx-home"></i>
                    </div>
                    <div className="menu-title">Dashboard</div>
                  </Link>
                </li>
              ) : (null)} */}

            {Modules.map((item) => {
              return (
                <ModuleComponent
                  key={item.ModuleID}
                  item={item}
                  Modules={Modules}
                  MiniBar={MiniBar}
                  MiniBarStyle={MiniBarStyle}
                />
              );
            })}
          </ul>
        </div>

        <header>
          <div className="topbar d-flex align-items-center">
            <nav className="navbar navbar-expand">
              <div
                className="mobile-toggle-menu"
                onClick={() => window.togglebar()}
              >
                <i className="bx bx-menu"></i>
              </div>

              <div className="top-menu ms-auto" style={{ display: "flex" }}>
                <li
                  className="nav-item dropdown dropdown-large"
                  style={{
                    listStyleType: "none",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                >
                  <div className="select-wrapper">
                    <TimezoneSelect
                      value={selectedTimezone}
                      onChange={handleChange}
                      timezones={{
                        ...allTimezones,
                        'Africa/Johannesburg': 'South Africa/Johannesburg',
                      }}
                    />
                  </div>
                </li>
                &nbsp;
                &nbsp;
                <li
                  className="nav-item dropdown dropdown-large"
                  style={{
                    listStyleType: "none",
                    fontSize: "x-large",
                    cursor: "pointer",
                  }}
                >
                  <i
                    className="animated bx bx-sun"
                    onClick={() => window.theme_theme()}
                  ></i>
                </li>
              </div>
              <div className="user-box dropdown">
                <a
                  className="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret"
                  href="#a"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >

                  <img
                    src={localStorage.getItem("ProfileImage") !== "" ? localStorage.getItem("ProfileImage") : Logo}
                    className="user-img"
                    alt="user avatar"
                    style={{
                      background: localStorage.getItem("ProfileImage") === "" ? "#000" : "",
                      border: "1px solid #000"
                    }}
                  />

                  {/* {parseInt(localStorage.getItem("ClientCountryId")) !== 0 ?

                    localStorage.getItem("CountryCode") !== "" ?
                      <ReactCountryFlag
                        className=""
                        countryCode={localStorage.getItem("CountryCode")}
                        aria-label={localStorage.getItem("CountryCode")}
                        style={{
                          lineHeight: "0em",
                          fontSize: "1.4em",
                          marginRight: "-11px"
                        }}
                      /> :
                      <i
                        className="bx bx-flag"
                        title="Country"
                        style={{ fontSize: "20px", cursor: "pointer", marginRight: "-11px" }}
                      ></i>
                    :
                    parseInt(localStorage.getItem("ClientId")) !== 0 ?

                      <img
                        src={localStorage.getItem("ClientLogo") !== "" ? localStorage.getItem("ClientLogo") : Logo}
                        className="user-img"
                        alt="user avatar"
                        style={{
                          background: "#000",
                          border: "2px solid #000", marginRight: "-11px"
                        }}
                      /> :
                      <img
                        src={Logo}
                        className="user-img"
                        alt="user avatar"
                        style={{
                          background: "#000",
                          border: "2px solid #000"
                        }}
                      />

                  } */}

                  <div className="user-info ps-3">
                    <p className="user-name mb-0">
                      {localStorage.getItem("Name")}
                    </p>
                  </div>
                </a>
                <ul className="dropdown-menu dropdown-menu-end" >
                  {
                    [3].includes(parseInt(UserGroup.Id)) ?
                      <li className="dropdown-item cursor-pointer" onClick={() => {
                        navigate("/client_callback_settings", {
                          state: ClientId
                        });
                      }}>
                        <i className="bx bx-cog"></i>
                        <span>Callback Setting</span>
                      </li>
                      : null}
                  <Link className="dropdown-item" to="">
                    <li onClick={() => {
                      setChangePasswordFlag(true);
                    }} >
                      <i className="bx bx-key" type="solid"></i>
                      <span>Change Password</span>
                    </li>
                  </Link>
                  <li >
                    <Link className="dropdown-item" to="/logout">
                      <i className="bx bx-log-out-circle"></i>
                      <span>Logout</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>

        {error === "no_access" || Modules.length === 0 ? (
          <div
            style={{
              position: "absolute",
              zIndex: 11,
              height: "100vh",
              width: "100%",
              top: -60,
              left: 0,
              display: "flex",
            }}
          >
            <div
              className="card"
              style={{
                minWidth: "300px",
                height: "fit-content",
                margin: "auto",
                textAlign: "center",
                boxShadow: "0px 0px 5px 0px #5c5c5c",
              }}
            >
              <div className="card-body horizontal-scroll">
                <div className="row">
                  <div className="col-md-12">
                    <p>You dont have any module access, please contact Administrator.</p>
                  </div>
                  <div className="col-md-12 text-center">
                    <button className="btn btn-success" onClick={() => setError("")}>Ok</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div
          className="overlay toggle-icon"
          onClick={() => window.togglebar()}
        ></div>

        <div
          className="page-wrapper"
          style={MiniBar ? { marginLeft: "70px", marginBottom: 0 } : { marginBottom: 0 }}
        >
          <div className="page-content" >{props.children}</div>
        </div>
      </div>
    </>
  );
};

export default Template;
