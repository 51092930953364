// https://yuzhva.github.io/react-leaflet-markercluster/?path=/docs/react-leaflet-markercluster--basic-example
// https://www.npmjs.com/package/leaflet.markercluster

// https://react-leaflet.js.org/docs/start-installation/

import React, { useMemo, useState, useRef } from 'react'
import L from "leaflet";
import defaultMarker from './default-circle.svg';
// import defaultMarker from './default-marker.svg';

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
// import MarkerClusterGroup from "./react-leaflet-markercluster";

export const MAP_CENTER_COORDINATES = [51.0, 19.0];
export const MAP_ZOOM = 6;
export const MAP_MAX_ZOOM = 18;

// const createClusterCustomIcon = function (cluster) {
//     return L.divIcon({
//         html: `<span>${cluster.getChildCount()}</span>`,
//         className: "marker-cluster-custom-outage",
//         iconSize: L.point(40, 40, true),
//     });
// };

const Map = (props) => {
    // const Statuses = { 0: "NO", 1: "OK", 2: "WARN", 3: "KO", 4: "OKKO" }

    const map = useRef();

    const [Stations, setStations] = useState([])
    // const [Markers, setMarkers] = useState([])

    const MarkerData = (OutageData, Filter, SiteSelected) => {
       
        const MarkerTemp = [];
        const Temp = []
        for (var i = 0; i < OutageData.length; i++) {
            MarkerTemp.push([OutageData[i].Latitude, OutageData[i].Longitude])
            if (Filter === '' && SiteSelected === '') {
                Temp.push(OutageData[i])
            }

            if (Filter === '' && SiteSelected === OutageData[i].SiteName) {
                Temp.push(OutageData[i])
            }



            if (Filter === 'Other' && OutageData[i].DeviceTypeGroup === 'Other' && SiteSelected === OutageData[i].Site) {
                Temp.push(OutageData[i])
            }

            if (Filter === 'Stationary' && OutageData[i].DeviceTypeGroup === 'Stationary' && SiteSelected === OutageData[i].Site) {
                Temp.push(OutageData[i])
            }

        }
        // setMarkers(MarkerTemp)
        setStations(Temp)
    }
    
    useMemo(() => MarkerData(props.OutageData, props.Filter, props.SiteSelected), [props.OutageData, props.Filter, props.SiteSelected])

    // Commended on 21-aug-23
    // useEffect(() => {
    //     if (Markers.length > 0 && map.current !== null) {
    //         var bounds = new L.LatLngBounds(Markers);
    //         map.current.fitBounds(bounds)
    //     }
    // }, [Markers])

    return (
        <MapContainer
            ref={map}
            className="markercluster-map"
            // center={[20.350709, 77.501064]}
          // center={props.OutageData.length ? [props.OutageData[0].Latitude, props.OutageData[0].Longitude] : [-19.906061, 24.256644]} ///12-aug-2023 commended
            center={props.OutageData.length ? [props.OutageData[0].Latitude, props.OutageData[0].Longitude] : [-28.116805, 24.232676]}
            zoom={MAP_ZOOM}
            maxZoom={MAP_MAX_ZOOM}
            style={{ height: "100%", width: "100%" }}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />

            {/* Pass Leaflet.markercluster option directly to MarkerClusterGroup as prop */}
            {/* <MarkerClusterGroup
                showCoverageOnHover={true}
                spiderfyDistanceMultiplier={2}
                iconCreateFunction={createClusterCustomIcon}
            > */}
                {
                    Stations.map((item, index) => {
                        return (
                            <Marker key={index}
                                icon={L.icon({
                                    iconUrl: defaultMarker,
                                    iconSize: [40, 40],
                                    iconAnchor: [20, 40],
                                })}
                                position={[item.Latitude, item.Longitude]}
                            >
                                {/* <Popup>
                                    <div className="marker-popup">
                                        <p>Device Id: <b>{item.DeviceId}</b></p>
                                        <p>Device Name: <b>{item.DeviceName}</b></p>
                                        <p>Site Name: <b>{item.Site}</b></p>
                                    </div>
                                </Popup> */}

                                <Popup>
                                    <div className="marker-popup">
                                        <table>
                                            <tbody>

                                                <tr>
                                                    <td style={{ minWidth: '100px' }}><b>Basestation Id:</b></td>
                                                    <td>{item.BaseStationId}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Site Name:</b></td>
                                                    <td>{item.SiteName}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Site Host:</b></td>
                                                    <td className='site_name'>{item.SiteHostName}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Bit Status:</b></td>
                                                    <td>{item.StateValue}</td>
                                                </tr>
                                                <tr>
                                                    <th>Comm Status:</th>
                                                    <td>{item.CommStateValue}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" style={{ textAlign: 'center' }}>
                                                        {(props.TicketsModuleAccess !== "none") ? <button
                                                            className="btn btn-primary m-2" title="Show Tickets" style={{ boxShadow: '0px 0px 5px 0px #4f4f4f', borderRadius: '15px', padding: '5px 20px' }}
                                                            onClick={(e) => { props.setBaseStationIdNavigation(item.BaseStationId) }}
                                                        >
                                                            
                                                            View Tickets
                                                        </button> : null}

                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Popup>
                            </Marker>
                        )
                    })
                }
            {/* </MarkerClusterGroup> */}
        </MapContainer>
    )
}

export default Map