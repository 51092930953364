import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from "react-toastify";
import { Link } from 'react-router-dom';
import axios from "../../axios";
import Loader from '../../Components/Loader';
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import Alert from "../../Components/Alert";
import Switch from "react-switch";
import DefaultLogo from "../../Images/foxilogic-disable.png"
import ReactCountryFlag from "react-country-flag"
import { CUSTOM_CLIENT_SELECT } from '../../Custom';

const List = () => {
    var UserGroup = JSON.parse(localStorage.getItem("UserGroup"));
    var url = new URL(window.location.href);

    const UrlClientId = localStorage.getItem("ClientId") > 0 ? localStorage.getItem("ClientId") : url.searchParams.get('ClientId') || ""
        ;

    const UrlCountryId = url.searchParams.get('CountryId') || ""

    const [ClientList, setClientList] = useState([]);
    const [ClientId, setClientId] = useState(UrlClientId);
    const [CountryList, setCountryList] = useState([]);
    const [CountryId, setCountryId] = useState(UrlCountryId);

    const location = useLocation();
    const [success, setSuccess] = useState("");
    const navigate = useNavigate();

    const [ModuleData, setModuleData] = useState([])
    const [loader, setLoader] = useState("")
    const [DeleteUserId, setDeleteUserId] = useState("");
    const [ServiceCall, setServiceCall] = useState(false);
    const [error, setError] = useState("");
    const [ModuleAccess, setModuleAccess] = useState("");

    useEffect(() => {
        if (UrlCountryId !== "") {
            setServiceCall(true);
        }
    }, [UrlCountryId])


    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess("");
        }
        return () => { };
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(error);
            setError("");
        }
        return () => { };
    }, [error]);

    useEffect(() => {
        if (location.state) {
            setSuccess(location.state);
            navigate("/basestation_specific_config?ClientId=" + ClientId + "&CountryId=" + CountryId, {
                state: null,
            });
        }
    }, [location, navigate, ClientId, CountryId]);

    useEffect(() => {
        for (var i = 0; i < ClientList.length; i++) {
            if (ClientList[i].Id === parseInt(ClientId)) {
                setCountryList(ClientList[i].countries)
                break
            }
        }
    }, [ClientId, ClientList]);

    useEffect(() => {
        if (UserGroup.Id === 1 || UserGroup.Id === 2 || UserGroup.Id === 3) {
            setLoader(true);
            axios
                .get("clients")
                .then((response) => {
                    var TempData = response.data.data.data;
                    var arr = [];
                    for (var i = 0; i < TempData.length; i++) {
                        var obj = TempData[i]
                        obj["label"] = TempData[i].Name;
                        obj["value"] = TempData[i].Id;
                        obj["image"] = TempData[i].ClientLogo ? TempData[i].ClientLogo : DefaultLogo;
                        arr.push(obj)
                    }
                    setClientList(arr)
                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                });
        }
    }, [UserGroup.Id]);

    const OnchangeClient = (selected) => {
        setClientId(selected.Id)
        setCountryId("");
    }

    const getClientValue = () => {
        if (ClientId) {
            for (var i = 0; i < ClientList.length; i++) {
                if (parseInt(ClientList[i].Id) === parseInt(ClientId)) {
                    return [{ value: ClientList[i].Id, label: ClientList[i].Name, image: ClientList[i].image }];
                }
            }
        }
    }

    useEffect(() => {
        if (ClientId && CountryId && ServiceCall) {
            setLoader(true)
            axios.get("get_all_bs_specific_config?ClientId=" + ClientId + "&CountryId=" + CountryId)
                .then((response) => {
                    setModuleData(response.data.data.data)
                    setModuleAccess(response.data.data.ModuleAccess)
                    setLoader(false)
                    setServiceCall(false)
                })
                .catch((error) => {
                    setLoader(false)
                })
        }

    }, [ClientId, CountryId, ServiceCall])

    const ChangeActiveStatus = (Id, Active) => {
        const Payload = {
            "Id": Id,
            "Active": parseInt(Active) === 0 ? 1 : 0,
            "ClientId": ClientId,
            "CountryId": CountryId
        }
        axios.post("change_active_status_bs_specific_config", Payload)
            .then((response) => {
                setServiceCall(true)
            })
            .catch((error) => {
            })
    }


    const AlertResponse = (response) => {
        if (response === true) {
            setLoader(true);
            axios
                .get("delete_bs_specific_config?DeleteId=" + DeleteUserId + "&ClientId=" + ClientId + "&CountryId=" + CountryId)
                .then((response) => {
                    setServiceCall(true);
                    setDeleteUserId("");
                    setSuccess("Record deleted successfully");
                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                });
        } else {
            setDeleteUserId("");
        }
    };

    return (
        <>
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}

            {DeleteUserId ?
                <Alert
                    message="Are you sure, you want to delete this record?"
                    AlertResponse={AlertResponse}
                />
                :
                ""
            }

            {/* {loader ? <Loader height={LoaderHeight} /> : null} */}
            <div className="row">
                <div className="col-md-8">
                    <h6 className="mb-0">Base Station Specific Configuration</h6>
                </div>

            </div>
            <hr />
            <div className="row">

                {UserGroup.Id === 1 || UserGroup.Id === 2 ? (
                    <>
                        <div className="col-md-2">
                            {CUSTOM_CLIENT_SELECT({ ClientListOptions: ClientList, Value: getClientValue(), OnchangeClient: OnchangeClient })}
                        </div>
                    </>

                ) : null}

                <div className="col-md-2">
                    {ClientId ? <select
                        className="form-select mb-3"
                        onChange={(e) => {
                            setCountryId(e.target.value)
                            setServiceCall(true);
                        }}

                        value={CountryId}
                    >
                        <option value="">Select Country</option>
                        {
                            CountryList.map((item, index) => (
                                <option key={index} value={item.Id}>
                                    {item.CountryCode ?
                                        <ReactCountryFlag
                                            className="add_country_flag mx-5"
                                            countryCode={item.CountryCode}
                                            aria-label={item.CountryCode}
                                            style={{
                                            }}
                                        />
                                        : <i
                                            className="bx bx-flag mx-1 p-0"
                                            title="Country"
                                            style={{ fontSize: "21px", cursor: "pointer" }}
                                        ></i>
                                    }
                                    &nbsp;&nbsp;&nbsp;{item.CountryName}
                                </option>
                            ))
                        }
                    </select> : null}
                </div>
                <div className="col-md-8">
                    {ClientId && CountryId && ModuleAccess === "write" ?
                        <Link to={"/add_basestation_specific_config/" + ClientId + "/" + CountryId} ><button
                            type="submit"
                            className="btn btn-primary px-5"
                            style={{ float: "right", marginLeft: "10px" }}
                        >
                            Add
                        </button></Link>
                        : null}
                </div>
            </div>

            {
                ClientId && CountryId ?
                    <div className="card" >
                        <div className="card-body horizontal-scroll">

                            <div className="row">
                                <div className="col-md-12">

                                    <table className="table mb-0 table-striped table-striped ticket-table table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Basestation Id</th>
                                                <th>Emails</th>
                                                <th>Active</th>
                                                {ModuleAccess === "write" ? <th>Action</th> : null}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ModuleData.length > 0 ?
                                                ModuleData.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.BasestationName}</td>
                                                            <td>{item.Email}</td>
                                                            <td>
                                                                <Switch
                                                                    className="login-switch"
                                                                    onColor={"#136dfa"}
                                                                    height={25}
                                                                    checkedIcon={false}
                                                                    uncheckedIcon={false}
                                                                    checked={parseInt(item.Active) === 1 ? true : false}
                                                                    onChange={() => ChangeActiveStatus(item.Id, item.Active)}
                                                                />
                                                            </td>
                                                            {ModuleAccess === "write" ?
                                                                <td className="action-buttons">
                                                                    <i
                                                                        className="bx bx-trash text-danger"
                                                                        title="Delete"
                                                                        onClick={() => setDeleteUserId(item.Id)}
                                                                    ></i>
                                                                    <Link to={"/add_basestation_specific_config/" + ClientId + "/" + CountryId + "/" + item.Id}>
                                                                        <i className="bx bx-pencil" title="Edit"></i>
                                                                    </Link>
                                                                </td> : null}
                                                        </tr>
                                                    );
                                                })
                                                :
                                                <tr>
                                                    <td
                                                        colSpan={7}
                                                        style={{ textAlign: "center" }}>
                                                        <b>No Alerts Found</b>
                                                    </td>
                                                </tr>}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </>
    )
}

export default List