import React, { useState, useEffect } from "react";
import axios from "../../axios";
import Loader from "../../Components/Loader";
import { ToastContainer } from "react-toastify";
import ReactCountryFlag from "react-country-flag";
import DefaultLogo from "../../Images/foxilogic-disable.png";
import { CUSTOM_CLIENT_SELECT } from "../../Custom";

const List = () => {
    const [loader, setLoader] = useState(false);
    const UserGroup = JSON.parse(localStorage.getItem("UserGroup"));

    const [ClientID, setClientID] = useState(localStorage.getItem("ClientId") > 0 ? localStorage.getItem("ClientId") : "");
    const [ClientList, setClientList] = useState([]);
    const [CountryID, setCountryID] = useState(localStorage.getItem("ClientCountryId") > 0 ? localStorage.getItem("ClientCountryId") : "");
    const [CountryList, setCountryList] = useState([]);
    const [BlockData, setBlockData] = useState(null);
    const [ServiceCall, setServiceCall] = useState(true);

    useEffect(() => {
        if (UserGroup.Id === 1 || UserGroup.Id === 2 || UserGroup.Id === 3) {
            setLoader(true);
            axios.get("get_clients")
                .then((response) => {
                    const TempData = response.data.data.map(client => ({
                        ...client,
                        label: client.Name,
                        value: client.Id,
                        image: client.ClientLogo || DefaultLogo
                    }));
                    setClientList(TempData);
                    setLoader(false);
                })
                .catch((error) => {
                    setLoader(false);
                });
        }
    }, [UserGroup.Id]);

    useEffect(() => {
        if (ClientID !== "") {
            setCountryID("");
            const selectedClient = ClientList.find(client => client.Id.toString() === ClientID.toString());
            setCountryList(selectedClient?.countries || []);
        }

    }, [ClientID, ClientList])

    const handleClientChange = (selected) => {
        setClientID(selected.Id);
    };

    const handleCountryChange = (event) => {
        const selectedCountryId = event.target.value;
        setCountryID(selectedCountryId);
    };

    useEffect(() => {
        if (ClientID !== "" && CountryID !== "" && ServiceCall) {
            setLoader(true);
            axios.get(`get_counts?ClientId=${ClientID}&ClientCountryId=${CountryID}`)
                .then((response) => {
                    setBlockData(response.data.data);
                    setServiceCall(false)
                    setLoader(false);
                })
                .catch((error) => { 
                    setServiceCall(false)
                    setLoader(false);
                }); 
        }
    }, [ClientID, CountryID, ServiceCall])

    return (
        <>
            <ToastContainer theme="colored" />
            {loader && <Loader />}

            <div>
                <div className="row">
                    <div className="col-md-8">
                        <h6 className="mb-0">Network Dashboard</h6>
                    </div>
                </div>
                <hr />
            </div>

            {/* Client Selection */}
            <div className="row">
                {(UserGroup.Id === 1 || UserGroup.Id === 2) && (
                    <div className="col-sm-3">
                        {CUSTOM_CLIENT_SELECT({
                            ClientListOptions: ClientList,
                            Value: ClientList.find(client => client.Id === parseInt(ClientID)) || null,
                            OnchangeClient: handleClientChange
                        })}
                    </div>
                )}

                {/* Country Selection */}
                {ClientID && CountryList.length > 0 && (
                    <div className="col-sm-3">
                        <select className="form-select mb-3" onChange={handleCountryChange} value={CountryID}>
                            <option value="">Select Country</option>
                            {CountryList.map((item, index) => (
                                <option key={index} value={item.Id}>
                                    {item.CountryCode ? (
                                        <ReactCountryFlag className="add_country_flag mx-1" countryCode={item.CountryCode} aria-label={item.CountryCode} />
                                    ) : (
                                        <i className="bx bx-flag mx-1 p-0" title="Country" style={{ fontSize: "21px", cursor: "pointer" }}></i>
                                    )}
                                    &nbsp;&nbsp;{item.CountryName}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
            </div>

            {/* Block Data Section (Only Show When Client & Country Are Selected) */}
            {BlockData && (
                < div className="card p-4">
                    <div className="row my-2 py-3 mx-1 network-dash-block" >

                        <div className="col-2 d-flex align-items-center">
                            <h5 className="m-0">Base Stations</h5>
                        </div>
                        <div className="col-10">
                            <div className="p-0 d-flex align-items-center">

                                <StatBlock title="Total BS" value={BlockData.BasestationCount} />
                                <StatBlock title="BS-NOC Surveillance" value={BlockData.TotalCounts} />
                                {
                                    BlockData.SiteStatusWiseBsCount.map((item, i) => {
                                        return (
                                            <>
                                                <StatBlock title={item.SiteStatusValue} value={item.TotalCount} />

                                            </>
                                        )
                                    })

                                }
                            </div>
                        </div>

                    </div>

                    <div className="row mt-3" style={{ height: "440px" }}>

                        <div className="col-sm-4" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <div className="network-dash-block py-2 d-flex justify-content-center align-items-center" >
                                <div className="row w-100 align-items-center">
                                    <div className="col-sm-4 py-3">
                                        <h5 className="m-0 text-center">Network Activity</h5>
                                    </div>
                                    <div className="col-sm-4  py-3">
                                        <h5 className="m-0 text-center"><StatBlock title="Status of Test API" value={BlockData.StatusOftestCount} /></h5>
                                    </div>
                                    <div className="col-sm-4  py-3">
                                        <h5 className="m-0 text-center"><StatBlock title="Callbacks (per day)" value={BlockData.SigfoxEventCount} /></h5>
                                    </div>
                                </div>
                            </div>
                            <div className="py-2 d-flex justify-content-center align-items-center">
                                <div className="row w-100 align-items-center">
                                    <div className="col-sm-4">

                                    </div>
                                    <div className="col-sm-4 text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" className="net-dash-arrow"><path d="M440-800v487L216-537l-56 57 320 320 320-320-56-57-224 224v-487h-80Z" /></svg>
                                    </div>
                                    <div className="col-sm-4 text-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" className="net-dash-arrow"><path d="M440-800v487L216-537l-56 57 320 320 320-320-56-57-224 224v-487h-80Z" /></svg>
                                    </div>
                                </div>
                            </div>
                            <div className="network-dash-block py-2 d-flex justify-content-center align-items-center" >
                                <div className="row w-100 align-items-center">
                                    <div className="col-sm-4  py-3">
                                        <h5 className="m-0 text-center">Tickets Generated / Day</h5>
                                    </div>
                                    <div className="col-sm-4  py-3">
                                        <h5 className="m-0 text-center"> <StatBlock title="" value={BlockData.TicketCount} /></h5>
                                    </div>
                                    <div className="col-sm-4  py-3">
                                        <h5 className="m-0 text-center"> <StatBlock title='' value={BlockData.Bs3GTicketCounts} /></h5>
                                    </div>

                                    <div className="col-sm-4  py-3">
                                        <h5 className="m-0 text-center">Noise Reduction(%)</h5>
                                    </div>
                                    <div className="col-sm-4  py-3">
                                        <h5 className="m-0 text-center"> <StatBlock title="" value={BlockData.TicketNoiseReduction + "%"} /></h5>
                                    </div>
                                    <div className="col-sm-4  py-3">
                                        <h5 className="m-0 text-center"> <StatBlock title='' value={BlockData.CallbackNoiseReduction + "%"} /></h5>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="network-dash-block py-2 d-flex justify-content-center align-items-center h-100">
                                <div className="row w-100 align-items-center h-100">

                                    <div className="col-sm-6  py-3">
                                        <h5 className="m-0 text-center">Devices Sync</h5>
                                    </div>
                                    <div className="col-sm-6  py-3">
                                        <p className="m-0 text-center">Device/Message API</p>
                                        <StatBlock title="" value={BlockData.SigfoxDeviceCount} />
                                    </div>

                                    <div className="col-sm-5  py-3 ">
                                        <p className="m-0 text-center">Tier 1 Noise Reduction</p>
                                    </div>
                                    <div className="col-sm-3 py-3">
                                        <span className="mx-3 my-0 text-nowrap" >{BlockData.DeviceNoiseReduction + "%"}</span>
                                    </div>
                                    <div className="col-sm-4  py-3">

                                        <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" className="net-dash-arrow"><path d="M440-800v487L216-537l-56 57 320 320 320-320-56-57-224 224v-487h-80Z" /></svg>
                                    </div>

                                    <div className="col-sm-6  py-3">
                                        <h5 className="m-0 text-center">Single Covered</h5>
                                    </div>
                                    <div className="col-sm-6  py-3">
                                        <StatBlock title="" value={BlockData.SingleCoveredDeviceCount} />
                                    </div>

                                    <div className="col-sm-5  py-3">
                                        <p className="m-0 text-center">Tier 2 Noise Reduction</p>
                                    </div>
                                    <div className="col-sm-3 py-3">
                                        <span className="mx-3 my-0 text-nowrap">{BlockData.SingleCoveredDeviceNoiseReduction + "%"}</span>
                                    </div>
                                    <div className="col-sm-4  py-3">

                                        <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" className="net-dash-arrow"><path d="M440-800v487L216-537l-56 57 320 320 320-320-56-57-224 224v-487h-80Z" /></svg>
                                    </div>

                                    <div className="col-sm-6  py-3">
                                        <h5 className="m-0 text-center">Impact Single Covered</h5>
                                    </div>
                                    <div className="col-sm-6  py-3">
                                        <StatBlock title="" value={BlockData.ImpactDeviceCount} />
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="network-dash-block py-2 d-flex justify-content-center align-items-center h-100">
                                <div className="row w-100 align-items-center h-100">

                                    <div className="col-sm-6  py-3">
                                        <h5 className="m-0 text-center">Total BS</h5>
                                    </div>
                                    <div className="col-sm-6  py-3">
                                        <p className="m-0 text-center">Base Station API</p>
                                        <StatBlock title="" value={BlockData.BasestationCount} />
                                    </div>

                                    <div className="col-sm-5  py-3 ">
                                        <p className="m-0 text-center">Tier 1 Noise Reduction</p>
                                    </div>
                                    <div className="col-sm-3 py-3">
                                        <span className="mx-3 my-0 text-nowrap" >{BlockData.BasestationNoiseReduction + "%"}</span>
                                    </div>
                                    <div className="col-sm-4  py-3">

                                        <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" className="net-dash-arrow"><path d="M440-800v487L216-537l-56 57 320 320 320-320-56-57-224 224v-487h-80Z" /></svg>
                                    </div>

                                    <div className="col-sm-6  py-3">
                                        <h5 className="m-0 text-center">BS Down</h5>
                                    </div>
                                    <div className="col-sm-6  py-3">
                                        <StatBlock title="" value={BlockData.BsDownCount} />
                                    </div>

                                    <div className="col-sm-5  py-3">
                                        <p className="m-0 text-center">Tier 2 Noise Reduction</p>
                                    </div>
                                    <div className="col-sm-3 py-3">
                                        <span className="mx-3 my-0 text-nowrap">{BlockData.BsDownNoiseReduction + "%"}</span>
                                    </div>
                                    <div className="col-sm-4  py-3">

                                        <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" className="net-dash-arrow"><path d="M440-800v487L216-537l-56 57 320 320 320-320-56-57-224 224v-487h-80Z" /></svg>
                                    </div>

                                    <div className="col-sm-6  py-3">
                                        <h5 className="m-0 text-center">BS Single Covered</h5>
                                    </div>
                                    <div className="col-sm-6  py-3">
                                        <StatBlock title="" value={BlockData.BsSingleCoveredCount} />
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            )}
        </>
    );
};

const StatBlock = ({ title, value }) => (

    < div class="summary-item">
        {title ? <span className="pb-1">{title}</span> : null}
        <h4 className="m-0 text-nowrap">{value || 0}</h4>

    </div>
);

export default List;
