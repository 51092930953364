import React, { useEffect, useState } from "react";
import axios from "../../axios";
import Loader from "../../Components/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditRecommendedFix from "./EditRecommendedFix";
import DefaultLogo from "../../Images/foxilogic-disable.png"
import { CUSTOM_CLIENT_SELECT } from '../../Custom';

const RecommendedFixList = () => {
    var UserGroup = JSON.parse(localStorage.getItem("UserGroup"));
    var Client = JSON.parse(localStorage.getItem("ClientId"));
    const [loader, setLoader] = useState(false);
    const [Clients, setClients] = useState([]);
    const [ClientID, setClientID] = useState(Client || "-1");
    const [ModuleData, setModuleData] = useState([]);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [recommentIndex, setRecommendIndex] = useState("")
    const [incidentType, setIncidentType] = useState("")
    const [EditText, setEditText] = useState("")
    const [LoaderHeight, setLoaderHeight] = useState("calc(100% + 120px");
    const [ModuleAccess, setModuleAccess] = useState("");

    const getClientValue = () => {
        if (ClientID) {
            for (var i = 0; i < Clients.length; i++) {
                if (Clients[i].Id === parseInt(ClientID)) {
                    return [{ value: Clients[i].Id, label: Clients[i].Name, image: Clients[i].image }];
                }
            }
        }
    }

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess("");
        }
        return () => { };
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(error);
            setError("");
        }
        return () => { };
    }, [error]);

    const OnchangeClient = (selected) => {
        setClientID(selected.Id)
    }

    useEffect(() => {
        if (UserGroup.Id === 1 || UserGroup.Id === 2) {
            setLoader(true);
            axios
                .get("clients")
                .then((response) => {

                    var TempData = response.data.data.data;
                    var arr = [];
                    for (var i = 0; i < TempData.length; i++) {
                        if (i === 0 && (UserGroup.Id === 1 || UserGroup.Id === 2)) {
                            var default_obj = {};
                            default_obj["Id"] = -1;
                            default_obj["Name"] = "Default Setting";
                            default_obj["label"] = "Default Setting";
                            default_obj["value"] = -1;
                            default_obj["image"] = DefaultLogo;
                            arr.push(default_obj)
                        }

                        var obj = TempData[i]
                        obj["label"] = TempData[i].Name;
                        obj["value"] = TempData[i].Id;
                        obj["image"] = TempData[i].ClientLogo ? TempData[i].ClientLogo : DefaultLogo;
                        arr.push(obj)
                    }
                    setClients(arr)
                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                });
        }
    }, [UserGroup.Id]);

    useEffect(() => {
        if (ClientID) {
            setLoader(true);
            axios.get("/list_recommended_fix?ClientId=" + ClientID)
                .then((response) => {
                    setModuleData(response.data.data.data);
                    setModuleAccess(response.data.data.ModuleAccess)
                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                });
        } else {
            setModuleData([]);
        }
    }, [ClientID]);

    const handleRecommendEdit = (getIndex, getIncidentType, getEditValue) => {
        setRecommendIndex(getIndex)
        setIncidentType(getIncidentType)
        setEditText(getEditValue)
    }

    const updateRecommendFix = (type) => {
        const param = {
            ClientId: ModuleData[recommentIndex].ClientId ? ModuleData[recommentIndex].ClientId : "-1",
            TestTypeId: ModuleData[recommentIndex].TestTypeId,
            IncidentCodeFlag: incidentType,
            RecommandedFix: EditText,
            SubmitType: type
        }
        setLoaderHeight("calc(100% + 90px)");
        setLoader(true);
        axios.put("update_recommended_fix", param)
            .then((response) => {
                setSuccess(response.data.status.message)
                setRecommendIndex("")
                setIncidentType("")
                setEditText("")
                setModuleData(response.data.data);
                setLoader(false);
                setLoaderHeight("100vh");
            })
            .catch((error) => {
                setLoader(false);
                setError(error.data.status.message)
            })
    }

    return (
        <div>
            <ToastContainer theme="colored" />
            {loader ? <Loader height={LoaderHeight} /> : null}
            {recommentIndex !== "" && incidentType !== "" ?
                <EditRecommendedFix
                    recommentIndex={recommentIndex}
                    ModuleData={ModuleData}
                    setIncidentType={setIncidentType}
                    setRecommendIndex={setRecommendIndex}
                    setEditText={setEditText}
                    updateRecommendFix={updateRecommendFix}
                    EditText={EditText}
                    incidentType={incidentType}
                    UserGroup={UserGroup}
                    ClientID={ClientID}
                    ModuleAccess={ModuleAccess}
                /> : null}

            <div className="row">
                <div className="col-md-8">
                    <h6 className="mb-0">Sigfox Recommended Fix Config</h6>
                </div>
            </div>
            <hr />

            <div className="card">
                <div className="card-body horizontal-scroll">
                    <div className="row">
                        <div className="col-md-6">
                            {UserGroup.Id === 1 || UserGroup.Id === 2 ? (
                                <>
                                    {CUSTOM_CLIENT_SELECT({ ClientListOptions: Clients, Value: getClientValue(), OnchangeClient: OnchangeClient })}
                                </>
                            ) : null}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div style={{ maxHeight: '73vh' }} className="horizontal-scroll">
                                <table
                                    className="table mb-0 table-striped text-center"
                                    style={{ verticalAlign: "middle" }}
                                >
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th style={{ textAlign: "left" }}>Incident Code</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ModuleData.length > 0 ?
                                            ModuleData.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td style={{ textAlign: "left" }}>
                                                            {item.IncidentCode}
                                                        </td>
                                                        <td>
                                                            {item.IsCodeToCheckSite === true ?
                                                                <>
                                                                    <span
                                                                        style={{ color: "blue", cursor: "pointer" }}
                                                                        onClick={() => handleRecommendEdit(index, "3RDPARTY", item.RecommendedFix3rdParty)}
                                                                    >
                                                                        <i
                                                                            className="bx bx-pencil" title="Recommended Fix 3rd Party"
                                                                        ></i>
                                                                        &nbsp;
                                                                        3rd Party</span>
                                                                    &nbsp; | &nbsp;
                                                                    <span
                                                                        style={{ color: "blue", cursor: "pointer" }}
                                                                        onClick={() => handleRecommendEdit(index, "CIRTECH", item.RecommendedFixCirtech)}
                                                                    >
                                                                        <i
                                                                            className="bx bx-pencil" title="Recommended Fix Cirtech"></i>
                                                                        &nbsp; Cirtech</span>

                                                                </>
                                                                :
                                                                <>
                                                                    <span
                                                                        style={{ color: "blue", cursor: "pointer" }}

                                                                        onClick={() => handleRecommendEdit(index, "NORMAL", item.RecommendedFix)}>

                                                                        <i
                                                                            className="bx bx-pencil" title="Recommended Fix"
                                                                        ></i>
                                                                    </span>
                                                                </>
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            }) :
                                            <tr>
                                                <td
                                                    colSpan={5}
                                                    style={{ textAlign: "center" }}>
                                                    <b>No Record Found</b>
                                                </td>
                                            </tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default RecommendedFixList