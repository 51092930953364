import React, { useEffect, useState } from 'react'
import axios from "../../axios";
import Loader from "../../Components/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DefaultLogo from "../../Images/foxilogic-disable.png"

const ViewTicketAutoCloseConfig = () => {
    const [loader, setLoader] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [Clients, setClients] = useState([]);
    const [ModuleAccess, setModuleAccess] = useState("");

    useEffect(() => {
        setLoader(true)
        axios
            .get("client_close_waiting_period_config")
            .then((response) => {
                setClients(response.data.data.data);
                setModuleAccess(response.data.data.ModuleAccess)
                setLoader(false);
            })
            .catch(() => {
                setLoader(false);
            });
    }, [])

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess("");
        }
        return () => { };
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(error);
            setError("");
        }
        return () => { };
    }, [error]);

    const handleChange = (e, getIndex) => {
        var input = e.target.value
        var ModuleDataTemp = JSON.parse(JSON.stringify(Clients));
        ModuleDataTemp[getIndex].CloseWaitingPeriod = input;
        setClients(ModuleDataTemp)
    }

    const submitTicketAutoClose = (e, getClientID, getWaitingValue) => {
        if (e.key === 'Enter' || e.type === 'click') {
            setLoader(true)
            axios.put("client_close_waiting_period/" + getClientID, { CloseWaitingPeriod: getWaitingValue })
                .then((response) => {
                    setSuccess(response.data.status.message)
                    setLoader(false)
                })
                .catch((error) => {
                    setLoader(false)
                })
        }
    }

    return (
        <div>
            <ToastContainer theme="colored" />
            {loader ? <Loader /> : null}
            <div className="row">
                <div className="col-md-8">
                    <h6 className="mb-0">Auto Close Waiting Config</h6>
                </div>
            </div>
            <hr />

            <div className="card">
                <div className="card-body horizontal-scroll">

                    <div className="row">
                        <div className="col-md-12">
                            <table
                                className="table mb-0 table-striped text-center"
                                style={{ verticalAlign: "middle" }}
                            >
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th style={{ textAlign: "left" }}>Client Name</th>
                                        <th style={{ textAlign: "left" }}>Close Waiting Period</th>
                                        {
                                            ModuleAccess === "write" ? <th style={{ textAlign: "left" }}>Action</th> : null}
                                    </tr>
                                </thead>
                                <tbody>
                                    {Clients.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td style={{ textAlign: "left" }}>
                                                    <img
                                                        src={item.ClientLogo ? item.ClientLogo : DefaultLogo}
                                                        className="user-img"
                                                        alt="Client Logo"
                                                    />
                                                    &nbsp;&nbsp;&nbsp;
                                                    {item.Name}</td>
                                                <td style={{ textAlign: "left", whiteSpace: "nowrap" }}>
                                                    <input
                                                        type="number"
                                                        name="Serverity"
                                                        className='form-control'
                                                        style={{ width: "40%", display: "inline-block" }}
                                                        value={item.CloseWaitingPeriod}
                                                        onChange={(e) => handleChange(e, index)}
                                                        onKeyDown={(e) => submitTicketAutoClose(e, item.Id, item.CloseWaitingPeriod)}
                                                    />
                                                    &nbsp;&nbsp;
                                                    <span>hours</span>
                                                </td>
                                                {
                                                    ModuleAccess === "write" ? <td style={{ textAlign: "left" }}>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary px-5"
                                                            onClick={(e) => submitTicketAutoClose(e, item.Id, item.CloseWaitingPeriod)}
                                                        // style={{ float: "right" }}
                                                        >
                                                            Save
                                                        </button>
                                                    </td> : null
                                                }

                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ViewTicketAutoCloseConfig